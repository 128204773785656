<template>
  <el-dialog
    :title="title"
    :visible.sync="showadd"
    width="50%"
    :before-close="onClose"
  >
    <div>
      <el-form ref="form" :model="form" label-width="130px" :rules="rules">
        <el-form-item label="标题" prop="title">
          <el-autocomplete
            v-model="form.title"
            :fetch-suggestions="handlerfetch"
            value-key="title"
            placeholder="请输入文章名称"
            @select="handleSelect"
            style="width: 340px"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="短标题" prop="short_title">
          <el-input
            v-model="form.short_title"
            placeholder="请输入文章标题"
            clearable
            style="width: 340px"
          />
        </el-form-item>
        <el-form-item label="虚拟阅读数(虚+真)" prop="virtual_view_count">
          <el-input
            v-model="totalview"
            placeholder=""
            clearable
            type="number"
            style="width: 150px"
          />
          <span>当前真实阅读数{{ form.view_count || 0 }}</span>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import uploadImg from '@/components/uploadImg'
  import WangEditor from '@/components/WangEditor'
  import { nextTick } from 'vue'

  export default {
    name: 'AddEdit',
    components: {
      uploadImg,
      WangEditor,
    },
    data() {
      return {
        showadd: false,
        title: '',
        shopSelect: [],
        form: {
          id: null,
          virtual_view_count: '',
          short_title: '',
        },
        rules: {
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        },
        totalview: '',
      }
    },
    watch: {
      // totalview(val) {
      //   if (val <= parseInt(this.form.view_count)) {
      //     this.$message.error('您设置的虚拟阅读数不能低于真实浏览量数据')
      //   }
      // },
    },
    mounted() {},
    methods: {
      handlerfetch(queryString, cb) {
        if (queryString == '') {
          cb([])
          this.form.title = ''
        } else {
          getAction('/api/stroll/article/select-article', {
            title: queryString || '',
          }).then((res) => {
            cb(res.data)
          })
        }
      },
      handleSelect(list) {
        this.form.id = list.id
        this.form.title = list.title
      },

      handlersave() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.form.virtual_view_count =
              this.totalview - parseInt(this.form.view_count)
            if (this.totalview <= parseInt(this.form.view_count)) {
              this.$message.error('您设置的虚拟阅读数不能低于真实浏览量数据')

              return false
            }
            postAction('/api/stroll/article/hot-edit', this.form).then(
              (res) => {
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
                this.onClose()

                this.$emit('getlist')
              }
            )
          } else {
            return false
          }
        })
      },
      onClose() {
        this.showadd = false
        this.$refs.form.resetFields()
        this.form = {
          id: '',
          short_title: '',
          virtual_view_count: '',
        }
      },
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
    },
  }
</script>

<style scoped>
  .industrytree /deep/.el-tree-node__children {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
</style>
