var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showadd,
        width: "50%",
        "before-close": _vm.onClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showadd = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "130px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "340px" },
                    attrs: {
                      "fetch-suggestions": _vm.handlerfetch,
                      "value-key": "title",
                      placeholder: "请输入文章名称",
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "短标题", prop: "short_title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "340px" },
                    attrs: { placeholder: "请输入文章标题", clearable: "" },
                    model: {
                      value: _vm.form.short_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "short_title", $$v)
                      },
                      expression: "form.short_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "虚拟阅读数(虚+真)",
                    prop: "virtual_view_count",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { placeholder: "", clearable: "", type: "number" },
                    model: {
                      value: _vm.totalview,
                      callback: function ($$v) {
                        _vm.totalview = $$v
                      },
                      expression: "totalview",
                    },
                  }),
                  _c("span", [
                    _vm._v("当前真实阅读数" + _vm._s(_vm.form.view_count || 0)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onClose } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }