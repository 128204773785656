<template>
  <div class="index-container">
    <h2>概况统计</h2>
    <el-form ref="form" inline label-suffix=":" :model="formg">
      <el-form-item>
        <el-date-picker
          v-model="timeg"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="gettotal">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerexportG">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="gtabledata" v-loading="gloading" style="width: 100%">
      <el-table-column prop="article" label="文章发布数" width="">
        <template slot="header" slot-scope="scope">
          <div slot="header">
            文章发布数
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">文章发布数：热点栏目上线以来发布文章总数</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="platform_uv" label="平台总UV" width="">
        <template slot="header" slot-scope="scope">
          <div slot="header">
            平台总UV
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">平台总UV：云交会平台总访客数</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="menu_uv" label="栏目总UV">
        <template slot="header" slot-scope="scope">
          <div slot="header">
            栏目总UV
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">栏目总UV：热点栏目内所有子页面累计访客数</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="icon_uv" label="首页热点UV">
        <template slot="header" slot-scope="scope">
          <div slot="header">
            首页热点UV
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">首页热点UV ：热点tab栏点击访客数</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="hot_main_uv" label="热点列表UV">
        <template slot="header" slot-scope="scope">
          <div slot="header">
            热点列表UV
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">热点列表UV ：热点首页访客数</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="hot_info_uv" label="热点产品详情页UV">
        <template slot="header" slot-scope="scope">
          <div slot="header">
            热点产品详情页UV
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                热点产品详情页UV：从热点文章进入产品详情页的访客数
              </div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="invite_uv" label="引流UV">
        <template slot="header" slot-scope="scope">
          <div slot="header">
            引流UV
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">引流UV：通过分享好友或者朋友圈带来访客数</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="no_invite_uv" label="站内UV">
        <template slot="header" slot-scope="scope">
          <div slot="header">
            站内UV
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">站内UV：云交会内部访客数</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="product_pv" label="产品PV">
        <template slot="header" slot-scope="scope">
          <div slot="header">
            产品PV
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">产品PV：通过热点进入产品详情页访问数量</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="product_uv" label="产品UV">
        <template slot="header" slot-scope="scope">
          <div slot="header">
            产品UV
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">产品UV：通过热点进入产品详情页访客数</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="关联产品电话数">
        <template slot="header" slot-scope="scope">
          <div slot="header">
            关联产品电话数
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                关联产品电话数：通过热点进入产品详情页，并且联系企业拨打电话
              </div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="message" label="关联留言数">
        <template slot="header" slot-scope="scope">
          <div slot="header">
            关联留言数
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                关联留言数：通过热点进入产品详情，并且完成留言
              </div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <h2>文章统计</h2>
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input
          v-model="form.title"
          clearable
          placeholder="请搜索"
          style="width: 220px"
        />
      </el-form-item>
      <el-form-item label="发布时间">
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="浏览时间">
        <el-date-picker
          v-model="scantime"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerexport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      border
      v-loading="tableloading"
      :data="tabledata"
      style="width: 100%"
      @sort-change="sortChange"
    >
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
        :sortable="item.sort"
      >
        <template #default="{ row }">
          <div v-if="item.label == '类型'">
            <span v-if="row[item.prop] == 1">店铺首页</span>
            <span v-else-if="row[item.prop] == 2">产品详情页</span>
            <span v-else-if="row[item.prop] == 3">H5链接</span>
            <span v-else-if="row[item.prop] == 4">无跳转</span>
            <span v-else-if="row[item.prop] == 5">热点文章</span>
          </div>
          <div v-else-if="item.label == '状态'">
            {{ row[item.prop] == 1 ? '上架' : '下架' }}
          </div>
          <div v-else-if="item.label == '头像'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '背景图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '置顶'">
            {{ row[item.prop] == 1 ? '置顶' : '取消置顶' }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- <add-edit ref="add" @getlist="handlerlist" /> -->
  </div>
</template>

<script>
  import { getAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  // import AddEdit from './components/edit.vue'

  export default {
    name: 'Index',
    // components: { AddEdit },
    data() {
      return {
        form: {
          title: '',
          page: 1,
          limit: 10,
          start_time: '',
          end_time: '',
        },
        formg: {
          start_time: '',
          end_time: '',
        },
        gtabledata: [], //概况统计
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'id',
            width: '50',
          },
          {
            label: '文章标题',
            prop: 'title',
            width: '110',
          },
          {
            label: '总阅读数',
            prop: 'total_view',
            width: '80',
            sort: 'custom',
          },
          {
            label: '总访客数',
            prop: 'uv',
            width: '',
            sort: 'custom',
          },
          {
            label: '虚拟阅读数',
            prop: 'virtual_view_count',
            width: '80',
            sort: 'custom',
          },
          {
            label: 'PV',
            prop: 'pv',
            width: '80',
            sort: 'custom',
          },
          {
            label: 'UV',
            prop: 'uv',
            width: '80',
            sort: 'custom',
          },
          {
            label: '朋友圈UV',
            prop: 'device_uv',
            width: '80',
            sort: 'custom',
          },
          {
            label: '引流UV',
            prop: 'invite_uv',
            width: '80',
            sort: 'custom',
          },
          {
            label: '站内UV',
            prop: 'no_invite_uv',
            width: '',
            sort: 'custom',
          },
          {
            label: '产品PV',
            prop: 'hot_pv',
            width: '',
            sort: 'custom',
          },
          {
            label: '产品UV',
            prop: 'hot_uv',
            width: '',
            sort: 'custom',
          },
          {
            label: '电话数',
            prop: 'phone_num',
            width: '',
            sort: 'custom',
          },
          {
            label: '留言数',
            prop: 'message_num',
            width: '',
            sort: 'custom',
          },
          {
            label: '样品申请数',
            prop: 'order_num',
            width: '',
            sort: 'custom',
          },
          {
            label: '来源',
            prop: 'author',
            width: '',
          },
          {
            label: '操作人',
            prop: 'admin_name',
            width: '',
          },
          {
            label: '发布时间',
            prop: 'push_time',
            width: '',
          },
        ],
        total: 0,
        areaselect: [],
        classselect: [],
        tableloading: false,
        gloading: false,
        staffSelect: [],
        time: [],
        timeg: [],
        scantime: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      timeg(v) {
        if (v) {
          this.formg.start_time = v[0]
          this.formg.end_time = v[1]
        } else {
          this.formg.start_time = ''
          this.formg.end_time = ''
        }
      },
      scantime(v) {
        if (v) {
          this.form.scan_start_time = v[0]
          this.form.scan_end_time = v[1]
        } else {
          this.form.scan_start_time = ''
          this.form.scan_end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      this.gettotal()
      // this.handlerSelect()
    },
    methods: {
      //排序
      sortChange({ column, prop, order }) {
        console.log('column, prop, order', column, prop, order)
        this.form.order = prop
        // 值 asc 小到大  和  desc  大到小]
        if (order === 'descending') {
          this.form.sort = 'desc'
        } else if (order === 'ascending') {
          this.form.sort = 'asc'
        } else {
          this.form.sort = ''
          this.form.order = ''
        }
        this.handlerInquire()
      },
      handlerexportG() {
        getAction('/api/stroll/article/total-tj-export', {
          start_time: this.formg.start_time,
          end_time: this.formg.end_time,
        }).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
        })
      },
      handlerexport() {
        getAction('/api/stroll/article/tj-export', {
          title: this.form.title,
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          scan_start_time: this.form.scan_start_time,
          scan_end_time: this.form.scan_end_time,
        }).then((res) => {
          excel.export_json_to_excel({
            header: res.data.header,
            data: res.data.data,
            filename: res.data.name,
          })
        })
      },
      gettotal() {
        this.gloading = true
        getAction('/api/stroll/article/total-tj', this.formg).then((res) => {
          if (res.code == 0) {
            this.gtabledata = [res.data]
          }
          this.gloading = false
        })
      },
      handleradd(row) {
        this.$refs.add.showadd = true
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handleredit(row) {
        this.$refs.add.showadd = true
        console.log('编辑显示,', row)
        this.$refs.add.handlerinfo(row)
        // if (row) {
        //   this.$refs.add.form = JSON.parse(JSON.stringify(row))
        // }
      },
      handlerstatus(row) {
        getAction('/api/stroll/article/status', { id: row.id }).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg)
            this.handlerlist()
          }
        })
      },
      // async handlerSelect() {
      //   const { data } = await getAction('/user/index/area', { pid: 0 })
      //   this.areaselect = data
      //   await getAction('/user/index/cate').then((res) => {
      //     this.classselect = res.data
      //   })
      // },
      handlerlist() {
        this.tableloading = true
        getAction('/api/stroll/article/tj', this.form).then((res) => {
          this.tabledata = res.data.list
          this.total = Number(res.totalCount)
          this.tableloading = false
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
