<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="25%"
    @close="close"
  >
    <el-form
      ref="form"
      :inline="true"
      label-width="80px"
      :model="form"
      :rules="rules"
    >
      <el-form-item
        label="浏览时长弹出"
        label-width="120px"
        prop="cat_popup_scan"
      >
        <el-input v-model.trim="form.cat_popup_scan" />
      </el-form-item>
      <el-form-item
        label="每日弹出次数"
        label-width="120px"
        prop="cat_popup_count"
      >
        <el-input v-model.trim="form.cat_popup_count" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button :disabled="disabled" type="primary" @click="save()">
        确 定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  export default {
    name: 'Config',
    data() {
      return {
        disabled: false,
        form: {
          cat_popup_scan: '',
          cat_popup_count: '',
        },
        rules: {
          cat_popup_scan: [
            { required: true, trigger: 'blur', message: '请配置浏览时长弹出' },
          ],
          cat_popup_count: [
            { required: true, trigger: 'blur', message: '请配置每日弹出次数' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit() {
        this.title = '公共配置'
        getAction('/api/system/conf/cat-popup-conf').then((res) => {
          this.form.cat_popup_scan = res.data.cat_popup_scan
          this.form.cat_popup_count = res.data.cat_popup_count
        })
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const loading = this.$loading({
              lock: true,
              text: '操作处理中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
            })
            postAction('/api/system/conf/set-cat-popup', this.form).then(
              (res) => {
                if (res.request_code == 200) {
                  loading.close()
                  this.$message.success(res.msg)
                  this.$emit('fetch-data')
                  this.close()
                } else {
                  loading.close()
                  this.$message.error(res.msg)
                }
              }
            )
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style scoped>
  .el-form-item {
    width: 100%;
  }
</style>
