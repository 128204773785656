<template>
  <!-- <div></div> -->
  <div class="index-container">
    <!-- <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-button type="primary" @click="handleradd">新 增</el-button>
      </el-form-item>
    </el-form> -->

    <el-table border :data="tabledata" style="width: 100%" ref="ElTable">
      <el-table-column
        type="selection"
        width="55"
        align="center"
        :reserve-selection="true"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '来源'">头条拉取</div>
          <div v-else-if="item.label == '拉取状态'">
            {{ row[item.prop] == 1 ? '已拉取' : '未拉取' }}
          </div>
          <div v-else-if="item.label == '封面图'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>

          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-popconfirm title="确认要删除吗？" @confirm="deleteRow(row)">
            <el-button
              style="margin-left: 10px"
              slot="reference"
              size="small"
              type="text"
            >
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <addedit ref="addedit" @getlist="handlerInquire"></addedit>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import addedit from './components/addedit.vue'

  export default {
    name: 'Index',
    components: { addedit },
    data() {
      return {
        form: {
          page: 1,
          limit: 10,
          is_del: 0, //1是 0否
        },
        checkselect: [
          {
            id: -1,
            name: '全部',
          },
          {
            id: 0,
            name: '未审',
          },
          {
            id: 1,
            name: '已审',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'id',
            prop: 'id',
            width: '80',
          },
          {
            label: '标题',
            prop: 'title',
            width: '',
          },
          {
            label: '短标题',
            prop: 'short_title',
            width: '',
          },

          {
            label: '发布时间',
            prop: 'push_time',
            width: '80',
          },
          {
            label: '虚拟阅读数',
            prop: 'total_view',
            width: '',
          },
          {
            label: '访客数UV',
            prop: 'uv',
            width: '',
          },
          {
            label: '点击次数',
            prop: 'hot_click',
            width: '',
          },
        ],
        total: 0,
        areaselect: [],
        classselect: [],
        selectRows: [],
        time: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      deleteRow(row) {
        console.log('删除ID，', row)
        postAction('/api/stroll/article/hot-del', { id: row.id }).then(
          (res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              this.handlerInquire()
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handleradd() {
        this.$refs.addedit.showadd = true
      },
      handleredit(row) {
        this.$refs.addedit.showadd = true
        this.$refs.addedit.form = JSON.parse(JSON.stringify(row))
        this.$refs.addedit.totalview =
          parseInt(row.virtual_view_count) + parseInt(row.view_count)
      },
      handlerlist() {
        getAction('/api/stroll/article/hot-index', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
