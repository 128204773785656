<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="40%"
    @close="close"
  >
    <el-form
      ref="form"
      :inline="true"
      label-width="80px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="弹窗类型" label-width="120px" prop="type">
        <el-select v-model.trim="form.type" disabled style="width: 250px">
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.type == 1"
        label="品类名称"
        label-width="120px"
        prop="cat_pname"
      >
        <el-input v-model.trim="form.cat_pname" disabled />
      </el-form-item>
      <el-form-item label="福利弹窗图" label-width="120px" prop="welfare_pic">
        <upload-img
          ref="welfare_pic"
          info-text="尺寸：750*104"
          :limit="1"
          @getImgs="getGoods_imgImg($event, 'welfare_pic')"
        />
      </el-form-item>
      <el-form-item label="领取弹窗图" label-width="120px" prop="receive_pic">
        <upload-img
          ref="receive_pic"
          info-text="尺寸：300*300，太小图会虚"
          :limit="1"
          @getImgs="getGoods_imgImg($event, 'receive_pic')"
        />
      </el-form-item>
      <el-form-item label="弹窗配置" label-width="120px" prop="rule_type">
        <el-radio-group v-model="form.rule_type">
          <el-radio-button :label="1">公共配置</el-radio-button>
          <el-radio-button :label="2">独立配置</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="form.rule_type == 2"
        label="弹窗次数"
        label-width="120px"
        prop="popup_num"
      >
        <el-input v-model.trim="form.popup_num" />
      </el-form-item>
      <el-form-item
        v-if="form.rule_type == 2"
        label="浏览时长"
        label-width="120px"
        prop="views_time"
      >
        <el-input v-model.trim="form.views_time" />
      </el-form-item>
      <el-form-item label="弹窗开关" label-width="120px" prop="door">
        <el-select v-model.trim="form.door">
          <el-option
            v-for="item in doorOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button :disabled="disabled" type="primary" @click="save()">
        确 定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
  import UploadImg from '@/components/uploadImg.vue'
  import { nextTick } from 'vue'
  import { postAction } from '@/api/request'
  export default {
    name: 'Edit',
    components: { UploadImg },
    data() {
      return {
        typeOptions: [
          { label: '品类弹窗', value: '1' },
          { label: '新品弹窗', value: '2' },
        ],
        doorOptions: [
          { label: '开启', value: '1' },
          { label: '关闭', value: '2' },
        ],
        disabled: false,
        form: {
          id: '',
          type: '',
          cat_pname: '',
          welfare_pic: '',
          receive_pic: '',
          rule_type: 1,
          popup_num: '',
          views_time: '1',
          door: '',
        },
        rules: {
          type: [
            { required: true, trigger: 'blur', message: '请选择弹窗类型' },
          ],
          welfare_pic: [
            { required: true, trigger: 'blur', message: '请输入海报名称' },
          ],
          receive_pic: [
            { required: true, trigger: 'blur', message: '请上传海报图片' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
      },
      showEdit(row) {
        this.title = '编辑弹窗'
        this.form = Object.assign({}, row)
        nextTick(() => {
          this.setImg(row.receive_pic, 'receive_pic')
          this.setImg(row.welfare_pic, 'welfare_pic')
        })
        this.dialogFormVisible = true
      },
      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.$refs['receive_pic'].img = []
        this.$refs['welfare_pic'].img = []
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (this.form.rule_type == 2) {
            if (this.form.popup_num <= 0) {
              this.$message.error('请配置弹出次数')
              return false
            }

            if (this.form.views_time <= 0) {
              this.$message.error('请配置浏览时长')
              return false
            }
          }
          if (valid) {
            const loading = this.$loading({
              lock: true,
              text: '操作处理中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
            })
            postAction('/api/system/cat-popup/edit', this.form).then((res) => {
              if (res.request_code == 200) {
                loading.close()
                this.$message.success(res.msg)
                this.$emit('fetch-data')
                this.close()
              } else {
                loading.close()
                this.$message.error(res.msg)
              }
            })
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style scoped>
  .el-form-item {
    width: 100%;
  }
</style>
