var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("matelist", { ref: "mate" }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "线上匹配+电话咨询+样品申请",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "position_name",
                      label: "广告位",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "type", label: "已完成", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#00a0e9",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerToRouter(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(scope.row["type"]) + " ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("h3", [_vm._v("总计：" + _vm._s(_vm.sum))]),
              _c("h4", [_vm._v("线上匹配明细")]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tabletow, border: "", "show-summary": "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "类型", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pic",
                      label: "已匹配数量",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "liveic",
                      label: "已查看数量",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }