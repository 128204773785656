<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="55%"
    append-to-body
    @close="close"
  >
    <el-form ref="form" :model="form" :inline="true" label-width="120px">
      <el-form-item :label="form.task_type==3?'广告位':'店铺'" prop="title">
        <el-input v-model.trim="mall_name" disabled style="width: 400px" />
      </el-form-item>
      <el-form-item label="任务模式" prop="plan_type">
        <el-radio-group v-model="form.plan_type">
          <el-radio label="1">单次任务</el-radio>
          <el-radio label="2">分时任务</el-radio>
        </el-radio-group>
        <div class="miaoshu">
          <i
            class="el-icon-warning"
            style="font-size: 15px; padding-right: 5px"
          />
          {{
            form.plan_type == 1
              ? form.task_type == 1
                ? '单次任务生效后直接增加到店铺虚拟浏览量'
                : form.task_type == 2
                ?'单次任务生效后直接增加到产品虚拟浏览量'
                :'单次任务生效后直接增加到广告虚拟曝光量'
              : form.task_type == 1
                ? '分时任务生效后在设置的时长内逐渐缓升到对应的店铺虚拟浏览量':
                form.task_type == 2
                ? '分时任务生效后在设置的时长内逐渐缓升到对应的产品虚拟浏览量'
                : '分时任务生效后在设置的时长内逐渐缓升到对应的广告虚拟曝光量'
          }}
        </div>
      </el-form-item>
      <el-form-item
        v-if="form.plan_type == 2"
        label="执行模式"
        prop="increase_mode"
      >
        <el-radio-group v-model="form.model">
          <el-radio label="1">立即执行</el-radio>
          <el-radio label="2">延时执行</el-radio>
        </el-radio-group>
        <!-- <div v-if="form.model == 2" class="miaoshu">
          <i
            class="el-icon-warning"
            style="font-size: 15px; padding-right: 5px"
          />
          此执行模式：直播预约状态只创建任务，开播后自动执行；直播开播状态直接执行任务
        </div> -->
      </el-form-item>
      <el-form-item
        v-if="form.plan_type == 2 && form.model == 2"
        label="开始时间"
      >
        <el-date-picker
          v-model="form.delay_time"
          type="datetime"
          format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间"
          :picker-options="pickerOptions"
          :default-time="'23:59:59'"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item v-if="form.plan_type == 2" label="执行频率" prop="">
        <el-input type="number" v-model="formdata.tian" style="width: 100px" />
        天
        <el-input type="number" v-model="formdata.shi" style="width: 100px" />
        时
        <el-input type="number" v-model="formdata.fen" style="width: 100px" />
        分
        <el-input v-model="formdata.miao" style="width: 100px"  min="30" type="number"/>
        秒
        <div class="miaoshu">
          <i
            class="el-icon-warning"
            style="font-size: 15px; padding-right: 5px"
          />
          执行频率指的是多久增加一次虚拟量，例如设置了1个小时，即每1个小时增加一次虚拟量。
        </div>
      </el-form-item>

      <el-form-item v-if="form.plan_type == 2" label="任务时长" prop="">
        <el-input type="number" v-model="formdata.tian1" style="width: 100px" />
        天
        <el-input type="number" v-model="formdata.shi1" style="width: 100px" />
        时
        <el-input type="number" v-model="formdata.fen1" style="width: 100px" />
        分
        <el-input v-model="formdata.miao1" style="width: 100px" type="number"/>
        秒
        <div class="miaoshu">
          <i
            class="el-icon-warning"
            style="font-size: 15px; padding-right: 5px"
          />
          任务时长指的是多久把所有的虚拟量增加完，例如设置了30天，即30天把所有的虚拟量添加完毕。
        </div>
      </el-form-item>

      <el-form-item label="增加虚拟量" prop="increase_num">
        <el-input v-model="form.num" style="width: 250px" />
      </el-form-item>
      <el-form-item label="当前虚拟量" prop="virtual_num">
        <el-input v-model="virtual_num" :disabled="true" style="width: 250px" />
        <div class="miaoshu">
          累计{{ form.task_type == 1 ? '店铺总浏览量' : form.task_type == 2 ? '产品总浏览量':'广告总曝光量' }}{{ views_num }}
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button :disabled="disabled" type="primary" @click="save()">
        确 定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
  // import { submitLiveLike, submitLiveWatch } from '@/api/toutiao/live.js'
  import { getAction } from '@/api/request'
  export default {
    name: 'EditLiveNum',
    data() {
      let disabledDate = (date) => {
        return date.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000
      }
      return {
        pickerOptions: {
          disabledDate,
          // selectableRange 用来限制时分秒的选择，这里要求只能选择当前时间之后到0点的时间点 但应该只限今天
          selectableRange: '00:00:00 - 23:59:59',
        },
        title: '',
        disabled: false,
        form: {
          data_id: '',
          task_type: '1',
          plan_type: '1',
          model: '1',
          second: 0,
          step_second:30,
          num: 0,
        },
        formdata:{
          tian:0,
          shi:0,
          fen:0,
          miao:30,
          tian1:0,
          shi1:0,
          fen1:0,
          miao1:0,
        },
        data_id: '',
        mall_name: '',
        views_num: 0,
        virtual_num: 0,
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        if (row.id) {
          // this.title = '编辑任务'
          // this.form.id = row.id
          // this.form.plan_type = row.plan_type
          // this.form.model = row.model
          // this.form.num = row.num
          // this.form.minute = row.minute
          // this.form.second = row.second
        } else {
          this.title = '创建任务'
        }
        console.log('--------------row,', row)
        this.form.data_id = row.data_id
        this.form.task_type = row.task_type
        this.mall_name = row.title
        this.views_num = row.views_num
        this.virtual_num = row.virtual_num
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.formdata = this.$options.data().formdata
        console.log(this.form)
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const loading = this.$loading({
              lock: true,
              text: '操作处理中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
            })
            // if (this.form.task_type == 1) {
              //1 店铺任务  2 产品任务
              // const { request_code, msg } = await submitLiveWatch(this.form)
              // if (request_code == 200) {
              //   loading.close()
              //   this.$message.success('操作成功')
              //   this.$emit('fetch-data')
              //   this.close()
              // } else {
              //   loading.close()
              //   this.$message.error(msg)
              // }
              //24-12-25贾春晖让修改三个类型为同一逻辑
         /*     getAction('/api/task/view-plan-task/submit-task', this.form)
                .then((res) => {
                  if (res.request_code == 200) {
                    this.$message.success('操作成功')
                    this.$emit('fetch-data')
                    this.close()
                  } else {
                    loading.close()
                    this.$message.error(res.msg)
                  }
                  loading.close()
                })
                .catch((err) => {
                  console.log('报错，', err)
                })
            } else {*/
              this.form.step_second = Number(this.formdata.tian)*24*60*60 +
                Number(this.formdata.shi)*60*60 + Number(this.formdata.fen)*60 + Number(this.formdata.miao)
              this.form.second = Number(this.formdata.tian1)*24*60*60 + Number(this.formdata.shi1)*60*60 +Number(this.formdata.fen1)*60 + Number(this.formdata.miao1)
              getAction('/api/task/view-plan-task/submit-task', this.form).then(
                (res) => {
                  if (res.request_code == 200) {
                    this.$message.success('操作成功')
                    this.$emit('fetch-data')
                    this.close()
                  } else {
                    loading.close()
                    this.$message.error(res.msg)
                  }
                  loading.close()
                }
              )
            }
          /*} else {
            return false
          }*/
        })
      },
    },
  }
</script>
<style scoped>
  .el-form-item {
    width: 100%;
  }
  .miaoshu {
    color: #d56a1d;
  }
</style>
