<template>
  <!-- <div></div> -->
  <div class="index-container">
    <el-form ref="form" inline label-suffix=":" :model="form">
      <el-form-item>
        <el-input v-model="form.title" clearable placeholder="标题" />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          start-placeholder="开始日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="tabledata"
      style="width: 100%"
      :row-key="getRowKey"
      @selection-change="setSelectRows"
      ref="ElTable"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
        :reserve-selection="true"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '来源'">
            {{
              row[item.prop] == 1
                ? '云交会'
                : row[item.prop] == 2
                ? '头条拉取'
                : '企业文章'
            }}
          </div>
          <div v-else-if="item.label == '拉取状态'">
            {{ row[item.prop] == 1 ? '已拉取' : '未拉取' }}
          </div>
          <div v-else-if="item.label == '图片1'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '图片2'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '图片3'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 100px; height: 100px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button
            size="small"
            style="margin-left: 10px"
            type="text"
            @click.native.prevent="handleredit(row)"
          >
            编辑
          </el-button>
          <el-popconfirm title="确认要删除吗？" @confirm="deleteRow(row)">
            <el-button
              style="margin-left: 10px"
              slot="reference"
              size="small"
              type="text"
            >
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" align="middle" justify="space-between">
      <!-- <el-col :span="6">
        <el-button type="warning" @click="handlerPulling">批量拉取</el-button>
      </el-col> -->
      <el-col :span="24">
        <el-pagination
          background
          :current-page="form.page"
          :layout="layout"
          :page-size="form.limit"
          style="text-align: center; margin-top: 10px"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-col>
    </el-row>
    <add-edit ref="add" @getlist="handlerInquire" />
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/request'
  import AddEdit from '../articlelist/components/articleedit.vue'

  export default {
    components: { AddEdit },
    name: 'Index',
    data() {
      return {
        form: {
          is_check: -1,
          class_id: '',
          name: '',
          mobile: '',
          source_id: '-1',
          province_id: '',
          page: 1,
          limit: 10,
          type: 2, //1逛逛 2热点
        },
        checkselect: [
          {
            id: -1,
            name: '全部',
          },
          {
            id: 0,
            name: '未审',
          },
          {
            id: 1,
            name: '已审',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: 'ID',
            prop: 'id',
            width: '80',
          },
          {
            label: '标题',
            prop: 'title',
            width: '220px',
          },

          {
            label: '作者',
            prop: 'author',
            width: '',
          },
          {
            label: '分拣时间',
            prop: 'created',
            width: '',
          },
        ],
        total: 0,
        areaselect: [],
        classselect: [],
        selectRows: [],
        time: [],
      }
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handleredit(row) {
        this.$refs.add.showadd = true
        this.$refs.add.handlerinfo(row)
      },
      handlerPulling() {
        if (this.selectRows.length > 0) {
          postAction('/api/stroll/article/la-qu', {
            ids: this.selectRows,
          }).then((res) => {
            this.$refs.ElTable.clearSelection()
            this.selectRows = []
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerInquire()
          })
        } else {
          this.$message({
            type: 'warning',
            message: '请先选择数据',
          })
        }
      },
      deleteRow(row) {
        console.log('删除ID，', row)
        postAction('/api/stroll/article/delete', { id: row.id }).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg)
            this.handlerInquire()
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // async handlerSelect() {
      //   const { data } = await getAction('/user/index/area', { pid: 0 })
      //   this.areaselect = data
      //   await getAction('/user/index/cate').then((res) => {
      //     this.classselect = res.data
      //   })
      // },
      getRowKey(row) {
        return row.article_id
      },
      setSelectRows(val) {
        this.selectRows = val.map((list) => {
          return list.article_id
        })
      },
      handlerlist() {
        this.form.source_id = '-1'
        this.form.is_draft = 1

        getAction('/api/stroll/article/index', this.form).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
