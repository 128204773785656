<template>
  <div class="activelist-list-container">
    <vab-query-form>
      <el-form :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item>
          <el-button icon="el-icon-plus" type="primary" @click="handleConfig">
            公共配置
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-select v-model.trim="queryForm.type">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model.trim="queryForm.door">
            <el-option
              v-for="item in doorOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="queryData">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form>
    <el-table
      v-loading="listLoading"
      border
      class="table"
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        label="ID"
        prop="id"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column align="center" label="类型" prop="type">
        <template #default="{ row }">
          <span v-if="row.type == 1">品类弹窗</span>
          <span v-else-if="row.type == 2">新品弹窗</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="品类" prop="cat_pname">
        <template #default="{ row }">
          <span v-if="row.cat_pname">{{ row.cat_pname }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="福利弹框图片" width="220">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.welfare_pic"
            style="width: 180px; height: 30px"
          >
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" label="领取弹窗图片" width="220">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.receive_pic"
            style="width: 60px; height: 60px"
          >
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="浏览时长"
        prop="views_time"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="弹出次数"
        prop="popup_num"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="弹窗开关" show-overflow-tooltip>
        <template #default="{ row }">
          <span v-if="row.door == 1">开启</span>
          <span v-else>关闭</span>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
    <config ref="config" @fetch-data="fetchData" />
  </div>
</template>
<script>
  import { getAction } from '@/api/request'
  import Edit from './components/edit.vue'
  import Config from './components/config.vue'

  export default {
    name: 'EditGuize',
    components: { Edit, Config },
    data() {
      return {
        dialogVisible: false,
        disabled: false,
        height: this.$baseTableHeight(2),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          type: '',
          door: '',
          pageNo: 1,
          pageSize: 10,
        },
        typeOptions: [
          {
            value: '',
            label: '全部',
          },
          {
            value: 1,
            label: '品类弹窗',
          },
          {
            value: 2,
            label: '新品弹窗',
          },
        ],
        doorOptions: [
          {
            value: '',
            label: '全部',
          },
          {
            value: 1,
            label: '开启',
          },
          {
            value: 2,
            label: '关闭',
          },
        ],
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleConfig() {
        this.$refs['config'].showEdit()
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 0
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        getAction('/api/system/cat-popup/lists', this.queryForm).then((res) => {
          this.list = res.data
          this.total = Number(res.totalCount)
        })
        this.listLoading = false
      },
    },
  }
</script>
<style></style>
