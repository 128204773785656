<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <!-- <div class="formHeader"> -->
      <el-form-item label="">
        <el-input v-model.trim="form.name" placeholder="姓名" />
      </el-form-item>
      <el-form-item label="">
        <el-input v-model.trim="form.mobile" placeholder="手机号" />
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="form.vip_status"
          clearable
          placeholder="状态"
          style="width: 200px"
        >
          <el-option
            v-for="(i, idx) in vipstatus"
            :key="idx"
            :label="i.name"
            :value="i.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="form.open_source"
          clearable
          placeholder="来源"
          style="width: 200px"
        >
          <el-option
            v-for="(i, idx) in sourceselect"
            :key="idx"
            :label="i.name"
            :value="i.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="form.club_vip_id" clearable placeholder="价格">
          <el-option
            v-for="(i, idx) in clubvipSelect"
            :key="idx"
            :label="i.title"
            :value="i.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="form.invite_user_id"
          clearable
          filterable
          placeholder="邀请人"
        >
          <el-option
            v-for="(i, idx) in staffSelect"
            :key="idx"
            :label="i.name"
            :value="i.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="form.is_experience"
          clearable
          filterable
          placeholder="是否体验会员"
        >
          <el-option
            v-for="(i, idx) in experienceSelect"
            :key="idx"
            :label="i.name"
            :value="i.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="payTime"
          end-placeholder="开通结束时间"
          range-separator="至"
          start-placeholder="开通开始时间"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="expireTime"
          end-placeholder="到期结束时间"
          range-separator="至"
          start-placeholder="到期开始时间"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onEdit()">新增</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onExport()">导出</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerImport()">导入</el-button>
      </el-form-item>
      <!-- </div> -->
    </el-form>
    <el-table
      ref="table"
      v-loading="loading"
      border
      :data="list"
      row-key="user_id"
    >
      <el-table-column align="center" label="ID" prop="id" width="90" />
      <el-table-column align="center" label="姓名" prop="name" />
      <el-table-column align="center" label="手机号码" prop="mobile" />
      <el-table-column
        align="center"
        label="公司名称"
        prop="company"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="套餐类型" prop="title" />
      <el-table-column
        align="center"
        label="价格"
        prop="total_price"
        width="90"
      />
      <el-table-column
        prop="vip_status"
        label="状态"
        align="center"
        width="110px"
      >
        <!-- :render-header="rendernum" -->
        <template slot="header" slot-scope="scope">
          <!-- <div>会员状态-=-=</div> -->
          <div slot="header">
            状态
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                已开通：有效期内可享受华糖会员/资讯权益
                <br />
                已过期：华糖会员/资讯权益已到期
                <br />
                未支付：会员订单未支付
                <br />
                未激活：线下打款但不是云交会用户
              </div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
        <template #default="{ row }">
          <span v-if="row.vip_status == 1">已开通</span>
          <span v-else-if="row.vip_status == 2">已过期</span>
          <span v-else-if="row.vip_status == 3">未激活</span>
          <span v-else-if="row.vip_status == 4">未支付</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="开通时间" prop="open_time" />
      <el-table-column align="center" label="到期时间" prop="expire_time" />
      <el-table-column align="center" label="微信支付订单号" prop="pay_no" />
      <el-table-column
        align="center"
        label="邀请人"
        prop="invite_user"
        show-overflow-tooltip
        width="90"
      />
      <el-table-column align="center" label="来源" prop="status" width="90">
        <template slot-scope="scope">
          {{ scope.row.platform == 5 ? '后台添加' : '前台支付' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="添加人"
        prop="operating_user"
        show-overflow-tooltip
        width="90"
      />
      <el-table-column align="center" label="操作" prop="" width="150">
        <template slot-scope="scope">
          <el-button type="text" @click="goDetail(scope.row)">详情</el-button>
          <el-button type="text" @click="goEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; padding: 20px 0"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <el-dialog
      :before-close="handleClose"
      :title="order_title"
      :visible.sync="editShow"
      width="50%"
    >
      <div class="editBox">
        <el-form
          ref="editform"
          label-width="130px"
          :model="editform"
          :rules="rules"
        >
          <!-- 订单修改前端限制：
1.仅限未激活订单可修改手机号
2.仅限已开通/已过期订单可修改过期时间
3.未激活/未支付订单可修改会员套餐类型 -->
          <!-- 已支付（有弹窗）、未激活（无弹窗）可修改手机号 -->
          <el-form-item label="选择用户" prop="mobile">
            <el-input
              v-model="editform.mobile"
              :disabled="
                editform.id &&
                (editform.vip_status == 2 || editform.vip_status == 4)
              "
              placeholder="请输入手机号"
              style="width: 260px"
            />
          </el-form-item>
          <el-form-item label="用户姓名" prop="name">
            <el-input
              v-model="editform.name"
              placeholder="请输入姓名"
              style="width: 260px"
            />
          </el-form-item>
          <el-form-item label="公司名称" prop="company">
            <el-input
              v-model="editform.company"
              placeholder="请输入公司名称"
              style="width: 260px"
            />
          </el-form-item>
          <el-form-item label="主营产品" prop="main_product">
            <el-input
              v-model="editform.main_product"
              placeholder="请输入主营产品"
              style="width: 260px"
            />
          </el-form-item>
          <el-form-item label="所在地区" prop="province">
            <el-select
              v-model="editform.province"
              clearable
              placeholder="省份"
              style="width: 120px"
              @change="handlerprovince"
            >
              <el-option
                v-for="list in provinceSelect"
                :key="list.id"
                :label="list.name"
                :value="list.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="city">
            <el-select
              v-model="editform.city"
              clearable
              placeholder="城市"
              style="width: 120px"
              @change="handlercity"
            >
              <el-option
                v-for="list in citySelect"
                :key="list.id"
                :label="list.name"
                :value="list.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="area">
            <el-select
              v-model="editform.area"
              clearable
              placeholder="地区"
              style="width: 120px"
            >
              <el-option
                v-for="list in districtSelect"
                :key="list.id"
                :label="list.name"
                :value="list.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input
              v-model="editform.address"
              placeholder="详细地址"
              style="width: 260px"
            />
          </el-form-item>
          <el-form-item label="获赠行业资讯" prop="get_industry_information">
            <el-radio
              :disabled="editform.vip_status == 4"
              v-model="editform.get_industry_information"
              label="1"
            >
              酒类
            </el-radio>
            <el-radio
              :disabled="editform.vip_status == 4"
              v-model="editform.get_industry_information"
              label="2"
            >
              食品类
            </el-radio>
          </el-form-item>
          <!-- 202412 未支付的禁止修改会员套餐 -->
          <!-- 20250110需求：已开通的禁止修改会员套餐 -->
          <el-form-item label="套餐" prop="club_vip_id">
            <el-select
              v-model="editform.club_vip_id"
              clearable
              :disabled="editform.vip_status == 4 || editform.vip_status == 1"
              placeholder="选择会员"
              style="width: 200px"
            >
              <el-option
                v-for="list in clubvipSelect"
                :key="list.id"
                :label="list.title"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
          <!-- 必填 默认正常 -->
          <el-form-item
            v-if="editform.club_vip_id != 4"
            label="会员类型"
            prop="is_experience"
          >
            <el-radio
              v-model="editform.is_experience"
              :disabled="experiencetype == 2"
              :label="'1'"
            >
              正常
            </el-radio>
            <el-radio
              v-model="editform.is_experience"
              :label="'2'"
              :disabled="experiencetype == 1"
            >
              体验
            </el-radio>
          </el-form-item>
          <!-- :checked="editform.experience_section.includes(list.id)" -->
          <el-form-item
            label="体验版块"
            prop="experience_section"
            required="true"
            v-if="editform.is_experience == 2"
          >
            <el-checkbox-group
              v-model="editform.experience_section"
              @change="handlechange"
            >
              <el-checkbox
                v-for="(list, index) in blockList"
                :key="index"
                :label="list.id"
              >
                {{ list.name }}
              </el-checkbox>
              <el-checkbox :disabled="!isHavetyj" :label="'4'">
                选品趋势报告
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 待激活的判断先会员类型  体验的可以改时间  正常的隐藏 -->
          <!-- 已过期的体验会员 不可修改到期时间 -->
          <!-- vip_status 会员订单状态 1.已开通 2.已过期 3.未激活 4.未支付 -->
          <el-form-item
            label="到期时间"
            required
            v-if="
              editform.vip_status == 1 ||
              editform.vip_status == 2 ||
              editform.vip_status == 4 ||
              (editform.vip_status == 3 && editform.is_experience == 2) ||
              editform.is_experience == 2
            "
          >
            <el-date-picker
              :disabled="
                editform.vip_status == 4 ||
                (editform.vip_status == 2 && editform.is_experience == 2)
              "
              v-model="editform.expire_time"
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
          <el-form-item label="微信支付订单号" prop="">
            <el-input
              v-model="editform.pay_no"
              placeholder="请输入微信支付订单号"
              style="width: 260px"
            />
          </el-form-item>

          <el-form-item v-if="editform.id" label="订单备注">
            <el-input
              v-model="editform.remark"
              rows="4"
              style="width: 400px"
              type="textarea"
            />
          </el-form-item>
          <el-form-item label="邀 请 人" prop="invite_user_id">
            <el-select
              v-model="editform.invite_user_id"
              clearable
              filterable
              placeholder="选择邀请人"
              style="width: 250px"
            >
              <el-option
                v-for="list in staffSelect"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="editSbmit">确 定</el-button>
      </span>
    </el-dialog>
    <info ref="info" />
    <import-ser ref="importSer" @getlist="getlist" />
  </div>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  import info from './components/detail'
  import ImportSer from './components/importSer.vue'
  import dateFormat from '@/utils/Timeformat'
  export default {
    name: 'Htmember',
    components: { info, ImportSer },
    data() {
      return {
        rules: {
          mobile: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
          ],
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          company: [
            { required: true, message: '请输入公司名称', trigger: 'blur' },
          ],
          main_product: [
            { required: true, message: '请输入主营产品', trigger: 'blur' },
          ],
          province: [
            { required: true, message: '请选择省份', trigger: 'blur' },
          ],
          city: [{ required: true, message: '请选择市', trigger: 'blur' }],
          area: [{ required: true, message: '请选择区', trigger: 'blur' }],
          address: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
          ],
          get_industry_information: [
            { required: true, message: '请选择获赠行业资讯', trigger: 'blur' },
          ],
          club_vip_id: [
            { required: true, message: '请选择会员', trigger: 'blur' },
          ],
          invite_user_id: [
            { required: true, message: '请选择', trigger: 'blur' },
          ],
          is_experience: [
            { required: true, message: '请选择', trigger: 'blur' },
          ],
        },
        loading: false,
        form: {
          name: '',
          mobile: '',
          pay_start_time: '',
          pay_end_time: '',
          expire_start_time: '',
          expire_end_time: '',
          page: 1,
          limit: 10,
        },
        payTime: '',
        expireTime: '',
        list: [],
        layout: 'total, sizes, prev, pager, next , jumper',
        total: 0,
        editShow: false,
        editform: {
          club_vip_id: '',
          name: '',
          mobile: '',
          company: '',
          main_product: '',
          province: '',
          city: '',
          area: '',
          address: '',
          pay_no: '',
          expire_time: null,
          is_experience: '1',
          experience_section: [],
          get_industry_information: '1',
        },
        provinceSelect: [],
        citySelect: [],
        districtSelect: [],
        clubvipSelect: [],
        vipstatus: [
          {
            id: 0,
            name: '全部',
          },
          {
            id: 1,
            name: '已开通',
          },
          {
            id: 2,
            name: '已过期',
          },
          {
            id: 3,
            name: '未激活',
          },
          {
            id: 4,
            name: '未支付',
          },
        ],
        sourceselect: [
          {
            id: 0,
            name: '全部',
          },
          {
            id: 1,
            name: '前台支付',
          },
          {
            id: 2,
            name: '后台添加',
          },
        ],
        experienceSelect: [
          {
            id: 1,
            name: '否',
          },
          {
            id: 2,
            name: '是',
          },
        ],
        blockList: [
          {
            id: '1',
            name: '糖烟酒周刊',
          },
          {
            id: '2',
            name: '管理实践课',
          },
          {
            id: '3',
            name: '线下研修',
          },
        ],
        staffSelect: [],
        isedit: false,
        initmobile: '', //初始手机号 用来比对是否修改手机号
        order_title: '',
        experiencetype: '', //会员类型 1 正常  2体验 判断是否禁用
        isHavetyj: false,
      }
    },
    watch: {
      'editform.experience_section'(n, o) {
        console.log('editform.experience_section,', n)
        this.isHavetyj = this.hasValue(n, '1')

        console.log('watch ,', this.isHavetyj)
      },
    },
    mounted() {
      this.getlist()
      this.getprov()
      this.getclubvip()
      this.getStaff()
    },
    methods: {
      // 体验版块
      handlechange(val) {
        console.log('监听数组，', val)
        if (!this.isHavetyj) {
          this.editform.experience_section =
            this.editform.experience_section.filter((item) => item != 4)
        }
      },
      hasValue(obj, value) {
        return Object.values(obj).some(function (o) {
          return o === value
        })
      },
      handlerImport() {
        this.$refs.importSer.showBatch = true
      },
      rendernum(h, { column, $index }) {
        console.log('render------------', h, column)
        return [
          column.label,
          h(
            'el-tooltip',
            {
              props: {
                content: `已开通：有效期内可享受华糖会员权益<br/>已过期：华糖会员权益已到期<br/>未支付：会员订单未支付<br/>未激活：线下打款但不是云交会用户`, // 鼠标悬停时要展示的文字提示
                placement: 'top', // 悬停内容展示的位置
              },
            },
            [h('span', { class: { 'el-icon-question': true } })] // 图标
          ),
        ]
      },
      // 查看详情
      goDetail(row) {
        let data = JSON.parse(JSON.stringify(row))
        this.$refs.info.form = data
        this.$refs.info.showDialog = true
      },
      // 获取员工列表
      getStaff() {
        getAction('api/common/common/users-staff-list').then((res) => {
          console.log('获取员工结果《', res)
          if (res.request_code == 200) {
            this.staffSelect = res.data
          }
        })
      },
      getlist() {
        let that = this
        postAction('/user/business-club/vip-user-list', that.form)
          .then((res) => {
            // console.log('ztq',res.data)
            if (res.code == 0) {
              that.list = res.data
              that.total = res.totalCount
            } else {
              that.$message.error(res.msg)
            }
          })
          .catch((res) => {
            that.$message.error(res.msg)
          })
      },
      onSubmit() {
        console.log(this.payTime)
        if (this.payTime != null && this.payTime != '') {
          this.form.pay_start_time = this.payTime[0]
          this.form.pay_end_time = this.payTime[1]
        } else {
          this.form.pay_start_time = ''
          this.form.pay_end_time = ''
        }
        if (this.expireTime != null && this.expireTime != '') {
          this.form.expire_start_time = this.expireTime[0]
          this.form.expire_end_time = this.expireTime[1]
        } else {
          this.form.expire_start_time = ''
          this.form.expire_end_time = ''
        }

        this.form.page = 1
        this.getlist()
      },
      handleCurrentChange(val) {
        console.log(val)
        this.form.page = val
        this.getlist()
      },
      handleSizeChange(val) {
        this.form.page = 1
        this.form.limit = val
        this.getlist()
      },
      onExport() {
        this.loading = true
        postAction('/user/business-club/vip-user-export', this.form).then(
          (res) => {
            console.log('ztq', res)
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.data,
              filename: res.data.name,
            })
            this.loading = false
          }
        )
      },
      goEdit(row) {
        this.isedit = true
        this.order_title = '新增订单'
        console.log('--------------', this.isedit)
        this.editform = JSON.parse(JSON.stringify(row))
        if (this.editform.is_experience == 1) {
          this.experiencetype = 1
        } else {
          this.experiencetype = '2'
        }
        if (
          this.editform.expire_time &&
          this.editform.expire_time.length > 0 &&
          this.editform.expire_time !== '--'
        ) {
          // console.log(
          //   '存在到期时间，',
          //   this.editform.expire_time.length,
          //   dateFormat(new Date()),
          //   this.editform.expire_time
          // )
        } else {
          //默认当前时间后一年365天
          console.log(
            '获取时间，',
            this.dateFormatAfterYear(new Date()) + '00:00:00'
          )
          this.editform.expire_time =
            this.dateFormatAfterYear(new Date()) + '00:00:00'
        }

        this.initmobile = this.editform.mobile
        // this.handlerprovince(this.editform.province)
        // this.handlercity(this.editform.city)
        this.onEdit()
      },
      dateFormatAfterYear(date) {
        console.log('=-=-=111-1-1-1-1-1,', date)
        var date = new Date(date) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        console.log('时间戳', date)
        var Y = date.getFullYear() + 1 + '-'
        var M =
          (date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1) + '-'
        var D =
          (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
        return Y + M + D
      },
      onEdit() {
        this.isedit = false
        this.editShow = true
        this.order_title = '新增订单'
        // this.editform.expire_time =
        //   this.dateFormatAfterYear(new Date()) + '00:00:00'
      },
      getclubvip() {
        postAction('/user/business-club/vip-info', {}).then((res) => {
          this.clubvipSelect = res.data
        })
      },
      getprov() {
        postAction('/user/index/area', { pid: 0 }).then((res) => {
          this.provinceSelect = res.data
        })
      },
      handlerprovince(e) {
        console.log(e)
        var pid = 0
        this.provinceSelect.forEach((item) => {
          if (item.name == e) {
            pid = item.id
          }
        })
        postAction('/user/index/area', { pid: pid }).then((res) => {
          this.citySelect = res.data
          this.editform.city = ''
          this.editform.area = ''
          this.districtSelect = []
        })
      },
      handlercity(e) {
        let that = this
        var pid = 0
        that.citySelect.forEach((item) => {
          if (item.name == e) {
            pid = item.id
          }
        })
        postAction('/user/index/area', { pid: pid }).then((res) => {
          that.districtSelect = res.data
          that.editform.area = ''
        })
      },
      editSbmit() {
        let that = this
        console.log('that.editform', that.editform)
        if (
          this.editform.is_experience == 2 &&
          this.editform.experience_section.length == 0
        ) {
          this.$message.error('请选择体验版块')
          return false
        }
        this.$refs['editform'].validate((valid) => {
          console.log('valid', valid)
          if (valid) {
            console.log('submit!', that.editform)
            var myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/
            if (!myreg.test(that.editform.mobile)) {
              that.$message.error('请输入正确的手机号码')
              return false
            }

            if (this.editform.id) {
              this.editform.order_id = this.editform.id
            }
            if (
              this.editform.vip_status == 1 &&
              this.editform.mobile !== this.initmobile
            ) {
              //开手机号是否一致

              this.$confirm('确认将华糖会员权益转给其他用户吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(async () => {
                  await postAction(
                    '/user/business-club/open-vip',
                    that.editform
                  ).then((res) => {
                    if (res.code == 0) {
                      that.$message.success(res.msg)
                      that.getlist()
                      that.editShow = false
                      that.editform = {
                        club_vip_id: '',
                        name: '',
                        mobile: '',
                        company: '',
                        main_product: '',
                        province: '',
                        city: '',
                        area: '',
                        address: '',
                        is_experience: '1',
                        experience_section: [],
                        get_industry_information: '',
                      }
                    } else {
                      that.$message.error(res.msg)
                    }
                  })
                })
                .catch(() => {
                  console.log('no')
                })
            } else {
              postAction('/user/business-club/open-vip', that.editform).then(
                (res) => {
                  if (res.code == 0) {
                    that.$message.success('保存成功')
                    that.getlist()
                    that.editShow = false
                    that.editform = {
                      club_vip_id: '',
                      name: '',
                      mobile: '',
                      company: '',
                      main_product: '',
                      province: '',
                      city: '',
                      area: '',
                      address: '',
                      is_experience: '1',
                      experience_section: [],
                      get_industry_information: '',
                    }
                  } else {
                    that.$message.error(res.msg)
                  }
                }
              )
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      handleClose() {
        this.editShow = false
        this.$nextTick(() => {
          this.experiencetype = ''
          this.editform = {
            club_vip_id: '',
            name: '',
            mobile: '',
            company: '',
            main_product: '',
            province: '',
            city: '',
            area: '',
            address: '',
            pay_no: '',
            expire_time: null,
            is_experience: '1',
            experience_section: [],
            get_industry_information: '',
          }
          console.log('---resest')
          this.$refs.editform.resetFields()
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .formHeader {
    display: flex;
    .el-form-item {
      margin-right: 15px;
    }
  }
</style>
