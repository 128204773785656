var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        top: "5vh",
        visible: _vm.showEditDialog,
        width: "40%",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showEditDialog = $event
        },
        close: _vm.onclose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "right",
            "label-width": "120px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "类型", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择路线类型" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "type",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.typeOptions, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "80%" },
                attrs: { placeholder: "请输入标题" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图片", prop: "img" } },
            [
              _c("upload-img", {
                ref: "img",
                attrs: {
                  "info-text": "建议尺寸：136*136",
                  limit: 1,
                  "max-size": 100,
                },
                on: {
                  getImgs: function ($event) {
                    return _vm.getGoods_imgImg($event, "img")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input", {
                staticStyle: { width: "100px" },
                attrs: { min: 1, placeholder: "请输入", type: "number" },
                model: {
                  value: _vm.form.sort_order,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort_order", $$v)
                  },
                  expression: "form.sort_order",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("启用")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("禁用")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.onclose()
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlerSave } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }