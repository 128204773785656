import Vue from 'vue'
import App from './App'
import i18n from './i18n'
import store from './store'
import router from './router'
import '@/vab'
import '@wangeditor/editor/dist/css/style.css'
import './utils/buttonPurview'

//拖动组件
// import VueDND from 'awe-dnd'
//
// Vue.use(VueDND)
/**
 * @description 正式环境默认使用mock，正式项目记得注释后再打包
 */
import { baseURL, pwa } from './config'
import { isExternal } from '@/utils/validate'

import '@wangeditor/editor/dist/css/style.css'



//百度富文本插件
import '../public/static/UEditor/jquery-1.11.1.min.js'
import '../public/static/UEditor/ueditor.config.js'
import '../public/static/UEditor/ueditor.all.js'
import '../public/static/UEditor/lang/zh-cn/zh-cn.js'
import '../public/static/UEditor/themes/default/css/ueditor.css'
import VueUeditorWrap from 'vue-ueditor-wrap' // ES6 Module
Vue.component('VueUeditorWrap', VueUeditorWrap)


import '@/utils/WebSocketManager'

import VueDND from 'awe-dnd'
Vue.use(VueDND)

if (process.env.NODE_ENV === 'production' && !isExternal(baseURL)) {
  const { mockXHR } = require('@/utils/static')
  mockXHR()
}

if (pwa) require('./registerServiceWorker')

Vue.config.productionTip = false
new Vue({
  el: '#app',
  i18n,
  store,
  router,
  render: (h) => h(App),
})
