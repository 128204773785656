var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "顶部Banner模板", "label-width": "120px" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.banner_model,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "banner_model", $$v)
                    },
                    expression: "form.banner_model",
                  },
                },
                [_vm._v("列表展示")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.form.banner_model,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "banner_model", $$v)
                    },
                    expression: "form.banner_model",
                  },
                },
                [_vm._v("轮播")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "最新活动模板", "label-width": "120px" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.event_model,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "event_model", $$v)
                    },
                    expression: "form.event_model",
                  },
                },
                [_vm._v("列表展示")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.form.event_model,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "event_model", $$v)
                    },
                    expression: "form.event_model",
                  },
                },
                [_vm._v("标签切换")]
              ),
            ],
            1
          ),
          _vm.form.event_model == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "标题", "label-width": "120px" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "250px" },
                    attrs: { placeholder: "最新活动" },
                    model: {
                      value: _vm.form.event_title,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "event_title",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.event_title",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.event_model == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "标签", "label-width": "120px" } },
                _vm._l(_vm.form.event_label, function (item, index) {
                  return _c(
                    "el-form-item",
                    { key: index },
                    [
                      _c("el-input", {
                        staticStyle: { width: "250px" },
                        attrs: { placeholder: "线下研修" },
                        model: {
                          value: item.title,
                          callback: function ($$v) {
                            _vm.$set(
                              item,
                              "title",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "item.title",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: index > 0,
                              expression: "index > 0",
                            },
                          ],
                          staticClass: "btnicon min",
                          on: {
                            click: function ($event) {
                              return _vm.moveUp(index)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-top",
                            staticStyle: {
                              "font-size": "18px",
                              "font-weight": "bold",
                              "margin-right": "5px",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: index < _vm.form.event_label.length - 1,
                              expression: "index < form.event_label.length - 1",
                            },
                          ],
                          staticClass: "btnicon min",
                          on: {
                            click: function ($event) {
                              return _vm.moveDown(index)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-bottom",
                            staticStyle: {
                              "font-size": "18px",
                              "font-weight": "bold",
                            },
                          }),
                        ]
                      ),
                      _c("el-switch", {
                        staticStyle: { "margin-left": "20px" },
                        attrs: {
                          "active-text": "启用",
                          "inactive-text": "禁用",
                          "active-value": "1",
                          "inactive-value": "2",
                        },
                        model: {
                          value: item.status,
                          callback: function ($$v) {
                            _vm.$set(item, "status", $$v)
                          },
                          expression: "item.status",
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-left": "120px" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSave } },
            [_vm._v("设置")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onPreview } },
            [_vm._v("预览")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            top: "5vh",
            visible: _vm.showPreview,
            width: "40%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPreview = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%", display: "inline-block" },
            attrs: {
              src: "https://res.httoutiao.com/yunjiaohui/images/houtai/pic.jpg",
              alt: "",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }