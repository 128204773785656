var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.dialogFormVisible, width: "40%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.disabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            inline: true,
            "label-width": "80px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "弹窗类型",
                "label-width": "120px",
                prop: "type",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "250px" },
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "type",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.typeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.type == 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "品类名称",
                    "label-width": "120px",
                    prop: "cat_pname",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.cat_pname,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "cat_pname",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.cat_pname",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "福利弹窗图",
                "label-width": "120px",
                prop: "welfare_pic",
              },
            },
            [
              _c("upload-img", {
                ref: "welfare_pic",
                attrs: { "info-text": "尺寸：750*104", limit: 1 },
                on: {
                  getImgs: function ($event) {
                    return _vm.getGoods_imgImg($event, "welfare_pic")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "领取弹窗图",
                "label-width": "120px",
                prop: "receive_pic",
              },
            },
            [
              _c("upload-img", {
                ref: "receive_pic",
                attrs: { "info-text": "尺寸：300*300，太小图会虚", limit: 1 },
                on: {
                  getImgs: function ($event) {
                    return _vm.getGoods_imgImg($event, "receive_pic")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "弹窗配置",
                "label-width": "120px",
                prop: "rule_type",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.rule_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "rule_type", $$v)
                    },
                    expression: "form.rule_type",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("公共配置"),
                  ]),
                  _c("el-radio-button", { attrs: { label: 2 } }, [
                    _vm._v("独立配置"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.rule_type == 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "弹窗次数",
                    "label-width": "120px",
                    prop: "popup_num",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.popup_num,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "popup_num",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.popup_num",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.rule_type == 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "浏览时长",
                    "label-width": "120px",
                    prop: "views_time",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.views_time,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "views_time",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.views_time",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "弹窗开关",
                "label-width": "120px",
                prop: "door",
              },
            },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.door,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "door",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.door",
                  },
                },
                _vm._l(_vm.doorOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }