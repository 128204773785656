<template>
  <el-dialog
    :title="''"
    :visible.sync="isShowDialog"
    width="90%"
    top="13vh"
    :close-on-click-modal="false"
    append-to-body
    destroy-on-close
    @close="onClose"
  >
    <div class="orderTest-container" v-loading="loading">
      <el-form ref="form" :model="form" inline>
        <el-form-item>
          <el-input
            placeholder="意向产品标题"
            clearable
            v-model="form.keywords"
            style="width: 160px"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.type"
            clearable
            placeholder="意向类型"
            filterable
          >
            <el-option
              v-for="list in typelist"
              :key="list.id"
              :label="list.title"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="time"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            start-placeholder="点击开始日期"
            end-placeholder="点击结束日期"
            :default-time="['12:00:00']"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-input
            placeholder="姓名"
            clearable
            v-model="form.name"
            style="width: 160px"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="公司名称"
            clearable
            v-model="form.company"
            style="width: 160px"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="手机号码"
            clearable
            v-model="form.mobile"
            style="width: 160px"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSearch">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerExport">导 出</el-button>
        </el-form-item>
      </el-form>
      <el-table border :data="tabledata" style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        >
          <template #default="{ row }">
            <div v-if="item.label == '封面图'">
              <el-image
                style="width: 70px; height: 70px"
                :src="row[item.prop]"
                :preview-src-list="[row[item.prop]]"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div v-else-if="item.label == '意向类型'">
              {{
                row[item.prop] == 2
                  ? '请企业联系我'
                  : row[item.prop] == 3
                  ? '查看产品更多详情'
                  : ''
              }}
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        :total="total"
        background
        style="text-align: center; margin-top: 10px"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
    <!-- <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div> -->
  </el-dialog>
</template>
<script>
  import { getAction, postAction } from '@/api/request'
  import * as excel from '@/utils/excel'
  export default {
    name: 'AddEditLive',
    data() {
      return {
        isShowDialog: false,
        loading: false,
        form: {
          keywords: '',
          type: '',
          name: '',
          company: '',
          mobile: '',
          start_time: '',
          end_time: '',
          page: 1,
          limit: 10,
        },
        tabledata: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '意向产品标题',
            prop: 'title',
            width: '',
          },

          {
            label: '关联云交会产品',
            prop: 'goods_name',
            width: '160',
          },

          {
            label: '所属店铺',
            prop: 'mall_name',
            width: '',
          },
          {
            label: '意向类型',
            prop: 'type',
            width: '',
          },
          {
            label: '点击时间',
            prop: 'created',
            width: '',
          },
          {
            label: '姓名',
            prop: 'apply_name',
            width: '',
          },
          {
            label: '公司名称',
            prop: 'company_name',
            width: '',
          },
          {
            label: '手机号码',
            prop: 'mobile',
            width: '',
          },
        ],
        total: 0,
        time: [],
        typelist: [
          {
            id: 2,
            title: '请企业联系我',
          },
          {
            id: 3,
            title: '查看产品更多详情',
          },
        ],
        productlist: [],
      }
    },
    watch: {
      time(v) {
        console.log('日期')
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.getlistdata()
      this.getproductlist()
    },
    methods: {
      getproductlist() {
        console.log('1111')
        getAction('/api/daily/new-daily/intention-list', { limit: 90 }).then(
          (res) => {
            this.productlist = res.data
          }
        )
      },
      onSearch() {
        this.form.page = 1
        this.getlistdata()
      },

      getlistdata() {
        this.loading = true
        console.log('cansh', this.form)
        getAction('/api/daily/new-daily/intention-list', this.form).then(
          (res) => {
            this.tabledata = res.data
            this.total = res.totalCount
            this.loading = false
          }
        )
      },
      handlerExport() {
        let data = JSON.parse(JSON.stringify(this.form))
        postAction('/api/daily/new-daily/intention-export', data).then(
          (res) => {
            excel.export_json_to_excel({
              header: res.data.header,
              data: res.data.data,
              filename: res.data.name,
            })
          }
        )
      },
      onClose() {
        this.isShowDialog = false
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getlistdata()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getlistdata()
      },
    },
  }
</script>
