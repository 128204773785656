<template>
  <el-dialog
    :close-on-click-modal="false"
    content
    :visible.sync="showEditPoster"
    width="800px"
  >
    <div>
      <el-form ref="form" label-width="140px" :model="form" :rules="rules">
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" maxlength="50" placeholder="名称" />
        </el-form-item>
        <el-form-item label="分享标题" prop="share_title">
          <el-input
            v-model="form.share_title"
            placeholder="分享标题"
            rows="4"
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="form.type" placeholder="设置栏目">
            <el-option
              v-for="item in typeSelect"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="分享小程序图" prop="share_mini_pic">
          <upload-img
            ref="share_mini_pic"
            :info-text="'分享小程序图，建议尺寸 500x400'"
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'share_mini_pic')"
          />
        </el-form-item>
        <el-form-item label="朋友圈分享图" prop="share_friend_pic">
          <upload-img
            ref="share_friend_pic"
            :info-text="'朋友圈分享图，建议尺寸400x400'"
            :limit="1"
            @getImgs="getGoods_imgImg($event, 'share_friend_pic')"
          />
        </el-form-item>
        <el-form-item label="内容图片" prop="pics">
          <!--          <upload-img ref="pics" :info-text="'内容图片支持拖拽排序，排序方式为最左边第一个显示为起始图片以此类推'" :limit="1000" @getImgs="getGoods_imgImgs($event, 'pics')"/>-->
          <upload-img-video
            ref="pics"
            :info-text="'内容图片、视频支持拖拽排序，排序方式为最左边第一个显示为起始图片或视频以此类推'"
            :limit="1000"
            :max-size="500"
            @getImgVideos="getGoods_VideoImgs($event, 'pics')"
          />
        </el-form-item>
        <el-form-item label="分享海报显示开关" prop="poster_door">
          <el-radio v-model="form.poster_door" :label="'1'">开</el-radio>
          <el-radio v-model="form.poster_door" :label="'0'">关</el-radio>
        </el-form-item>
        <div v-if="form.poster_door == '1'">
          <el-form-item label="显示权限" prop="poster_auth">
            <el-select v-model="form.poster_auth" placeholder="请选择">
              <el-option
                v-for="item in [
                  { id: '0', name: '所有人' },
                  { id: '1', name: '员工' },
                ]"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="分享海报图标" prop="poster_icon">
            <upload-img
              ref="poster_icon"
              :info-text="'建议尺寸：300*300'"
              :limit="1"
              @getImgs="getGoods_imgImg($event, 'poster_icon')"
            />
          </el-form-item>
          <el-form-item label="海报图片" prop="poster_pic">
            <upload-img
              ref="poster_pic"
              :info-text="'建议尺寸：350*1500，图片大小500k'"
              :limit="1"
              @getImgs="getGoods_imgImg($event, 'poster_pic')"
            />
          </el-form-item>
          <el-form-item label="二维码横坐标" prop="jump_url">
            <el-input
              v-model="form.poster_x"
              onkeyup="value=value.replace(/[^\d.]/g,'') "
              placeholder="请输入二维码横坐标"
            />
          </el-form-item>
          <el-form-item label="二维码纵坐标" prop="jump_url">
            <el-input
              v-model="form.poster_y"
              onkeyup="value=value.replace(/[^\d.]/g,'') "
              placeholder="请输入二维码纵坐标"
            />
          </el-form-item>
          <el-form-item label="二维码宽度" prop="jump_url">
            <el-input
              v-model="form.poster_qrcode_width"
              onkeyup="value=value.replace(/[^\d.]/g,'') "
              placeholder="请输入二维码宽度"
            />
          </el-form-item>
          <el-form-item label="二维码高度" prop="jump_url">
            <el-input
              v-model="form.poster_qrcode_height"
              onkeyup="value=value.replace(/[^\d.]/g,'') "
              placeholder="请输入二维码高度"
            />
          </el-form-item>
          <el-form-item label="预览">
            <el-button type="primary" @click="preview">预览</el-button>
          </el-form-item>
        </div>
        <el-form-item label="邀请记录显示开关">
          <el-radio v-model="form.invite_door" :label="'1'">开</el-radio>
          <el-radio v-model="form.invite_door" :label="'0'">关</el-radio>
        </el-form-item>
        <div v-if="form.invite_door == '1'">
          <el-form-item label="显示权限">
            <el-select v-model="form.invite_auth" placeholder="请选择">
              <el-option
                v-for="item in [
                  { id: '0', name: '所有人' },
                  { id: '1', name: '员工' },
                ]"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="邀请记录图标" prop="invite_icon">
            <upload-img
              ref="invite_icon"
              :info-text="'建议尺寸：300*300'"
              :limit="1"
              @getImgs="getGoods_imgImg($event, 'invite_icon')"
            />
          </el-form-item>
        </div>
        <el-form-item label="悬浮图标开关" prop="float_door">
          <el-radio v-model="form.float_door" :label="'1'">开</el-radio>
          <el-radio v-model="form.float_door" :label="'0'">关</el-radio>
        </el-form-item>
        <div v-if="form.float_door == '1'">
          <el-form-item label="悬浮图标" prop="float_icon">
            <upload-img
              ref="float_icon"
              :info-text="'建议尺寸：300*300'"
              :limit="1"
              @getImgs="getGoods_imgImg($event, 'float_icon')"
            />
          </el-form-item>
          <el-form-item label="跳转类型">
            <el-select v-model="form.jump_type" clearable placeholder="请选择">
              <el-option
                v-for="item in [
                  { id: '0', name: '不跳转' },
                  { id: '1', name: 'H5链接' },
                  { id: '2', name: '小程序链接' },
                ]"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="跳转链接">
            <el-input v-model="form.jump_url" placeholder="请输入跳转链接" />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showEditPoster = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getAction, uploadAction } from '@/api/request'
  import UploadImg from '@/components/uploadImg.vue'
  import { nextTick } from 'vue'
  import { Base64 } from 'js-base64'
  import base64_encode from '@/utils/base64_encoder'
  import { base64ToFile } from '@/utils/fileto'
  import UploadImgVideo from '@/components/uploadImgVideo.vue'
  export default {
    name: 'Addedit',
    components: { UploadImgVideo, UploadImg },
    data() {
      return {
        wechart:
          'https://res.httoutiao.com/htcard/test/up/202409/09/20240909112210241785646622.1725852130-1840137713.png',
        showEditPoster: false,
        staffOption: [],
        jumpSelect: [
          {
            id: '1',
            name: 'H5链接',
          },
          {
            id: '2',
            name: '报名H5',
          },
          {
            id: '3',
            name: '小程序链接',
          },
          {
            id: '4',
            name: '开通店铺绿色通道H5',
          },
        ],
        form: {
          type: '',
          title: '',
          share_title: '',
          share_mini_pic: '',
          share_friend_pic: '',
          pics: '',
          id: '',
          float_door: '0',
          invite_door: '0',
          poster_door: '0',
          invite_auth: '1',
          poster_pic: '',
          poster_icon: '',
          poster_x: '',
          poster_y: '',
          poster_qrcode_width: '',
          poster_qrcode_height: '',
          invite_icon: '',
          float_icon: '',
          jump_type: '',
          jump_url: '',
        },
        typeSelect: [],
        rules: {
          title: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            {
              min: 1,
              max: 50,
              message: '长度在 1 到 50 个字符',
              trigger: 'blur',
            },
          ],
          type: [{ required: true, message: '请选择类型', trigger: 'change' }],
          share_title: [
            { required: true, message: '请输入分享标题', trigger: 'change' },
          ],
          share_mini_pic: [
            {
              required: true,
              message: '请上传分享小程序图',
              trigger: 'change',
            },
          ],
          share_friend_pic: [
            {
              required: true,
              message: '请上传朋友圈分享图',
              trigger: 'change',
            },
          ],
          poster_icon: [
            { required: true, message: '请上传海报图标', trigger: 'change' },
          ],
          poster_pic: [
            { required: true, message: '请上传海报图', trigger: 'change' },
          ],
          invite_icon: [
            { required: true, message: '请上传邀请图标', trigger: 'change' },
          ],
          float_icon: [
            { required: true, message: '请上传悬浮图标', trigger: 'change' },
          ],
        },
      }
    },
    watch: {
      showEditPoster(val) {
        if (!val) {
          ;(this.form = {
            type: '',
            title: '',
            share_title: '',
            share_mini_pic: '',
            share_friend_pic: '',
            pics: '',
            id: '',
            float_door: '0',
            invite_door: '0',
            poster_door: '0',
            invite_auth: '1',
            poster_pic: '',
            poster_auth: '',
            poster_icon: '',
            poster_x: '',
            poster_y: '',
            poster_qrcode_width: '',
            poster_qrcode_height: '',
            invite_icon: '',
            float_icon: '',
            jump_type: '',
            jump_url: '',
          }),
            this.$refs.form.resetFields()
          this.closeimg('share_mini_pic')
          this.closeimg('share_friend_pic')
          this.closeimgvideo('pics')
          /*this.closeimg('poster_pic')
        this.closeimg('poster_icon')
        this.closeimg('invite_icon')
        this.closeimg('float_icon')*/
        }
      },
    },
    mounted() {
      getAction('/api/system/set-plan-report/type').then((res) => {
        this.typeSelect = res.data
      })
    },
    methods: {
      async preview() {
        if (this.form.poster_pic) {
          // const imgs = this.form.poster_icon.split('.com/')[1]
          await this.urlToBase64(this.wechart).then((res) => {
            // let fileimg = base64ToFile(res)

            const formData = new FormData()
            formData.append('file', base64ToFile(res))
            uploadAction('/api/index/upload', formData).then((e) => {
              console.log(e)
              if (e.request_code == 200) {
                console.log(e.data.url)
                const imgs = e.data.url.split('.com/')[1]
                const encode = Base64.encode(imgs)
                const imgurl2 = this.base64urlEncode(encode)
                let a =
                  this.form.poster_pic +
                  `?x-oss-process=image/watermark,image_${imgurl2},g_nw,x_${this.form.poster_x},y_${this.form.poster_y}`
                this.$alert(
                  `<img src="${a}" style="width: 380px;height: auto">`,
                  '预览图片',
                  {
                    dangerouslyUseHTMLString: true,
                  }
                )
              }
            })
          })
          /* const encode = Base64.encode(this.wechart)
      const imgurl2 = this.base64urlEncode(encode)
      let a = this.form.poster_pic+`?x-oss-process=image/watermark,image_${imgurl2},g_nw,x_${this.form.poster_x},y_${this.form.poster_y},w_${this.form.poster_qrcode_width},h_${this.form.poster_qrcode_height}`
      this.$alert(`<img src="${a}" style="width: 380px;height: auto">`, '预览图片', {
        dangerouslyUseHTMLString: true
      });*/
        }
      },
      async urlToBase64(url) {
        /*  try {
      // 从 URL 获取文件内容并转化为 Blob 对象
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();

      // 创建一个新的 File 对象
      const file = new File([blob], Date.now(), { type: 'png' });

      return file;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }*/
        return new Promise((resolve, reject) => {
          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d')
          let img = new Image()
          img.src = url
          img.crossOrigin = 'Anonymous'
          img.onload = () => {
            canvas.width = this.form.poster_qrcode_width
            canvas.height = this.form.poster_qrcode_height
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
            const dataURL = canvas.toDataURL('image/png')
            console.log(dataURL)
            resolve(dataURL)
          }
          img.onerror = function (e) {
            reject(e)
          }
        })
      },
      base64urlEncode(str) {
        // 将Base64编码中的 '+' 替换为 '-'，'/' 替换为 '_'，并移除结果中尾部的所有 '='
        return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
      },
      handlersave() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            getAction('/api/system/set-plan-report/edit', this.form).then(
              (res) => {
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
                this.$emit('getlist')
                this.showEditPoster = false
              }
            )
          }
        })
      },
      handlerinfo(row) {
        //   getAction('/api/system/staff-console/info',{id:row.id}).then(res=>{
        //     this.form=res.data
        // })
        this.form = Object.assign(this.form, row)
        this.form.type = Number(this.form.type)
        nextTick(() => {
          setTimeout(() => {
            this.setImgArray(this.form.pics, 'pics')
          }, 100)
          this.setImg(this.form.share_mini_pic, 'share_mini_pic')
          this.setImg(this.form.share_friend_pic, 'share_friend_pic')
          this.form.poster_door
          if (this.form.poster_door == '1') {
            this.setImg(this.form.poster_pic, 'poster_pic')
            this.setImg(this.form.poster_icon, 'poster_icon')
          }
          if (this.form.invite_auth == '1') {
            this.setImg(this.form.invite_icon, 'invite_icon')
          }
          if (this.form.float_door == '1') {
            this.setImg(this.form.float_icon, 'float_icon')
          }
        })
      },
      // 获取商品展示图写入form
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v[0]
        } else {
          this.form[prop] = ''
        }
      },
      getGoods_VideoImgs(v, prop) {
        if (v[0]) {
          this.form[prop] = v.join(',')
        } else {
          this.form[prop] = ''
        }
      },

      setImg(v, prop) {
        if (v) this.$refs[prop].img = [v]
      },
      setImgArray(v, prop) {
        if (v) this.$refs[prop].imgVideoArr = v.split(',')
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
      closeimgvideo(prop) {
        this.$refs[prop].imgVideoArr = []
      },
    },
  }
</script>

<style scoped></style>
