<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    top="5vh"
    :visible.sync="showEditDialog"
    width="50%"
    @close="onClose"
    append-to-body
  >
    <el-form
      ref="form"
      label-position="right"
      label-width="120px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="标题" prop="title">
        <el-input
          v-model="form.title"
          placeholder="请输入标题"
          style="width: 80%"
        />
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <vue-ueditor-wrap
          v-model="form.content"
          @before-init="addCustomDialog"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">取消</el-button>
      <el-button type="primary" @click="handlerSave">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        title: '活动介绍',
        showEditDialog: false,
        form: {
          id: '',
          title: '',
          content: '',
        },
        rules: {
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          content: [{ required: true, message: '请填写内容', trigger: 'blur' }],
        },
      }
    },
    methods: {
      addCustomDialog() {},
      handlerSave() {
        if (this.form.title == '') {
          this.$message.error('请填写标题')
          return false
        }
        if (this.form.content == '') {
          this.$message.error('请填写内容')
          return false
        }
        this.$emit('getdata', this.form)
        this.showEditDialog = false
      },
      onClose() {
        this.showEditDialog = false
        this.form = {
          id: '',
          title: '',
          content: '',
        }
      },
    },
  }
</script>
