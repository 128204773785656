var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.showedit, width: "90%" },
      on: {
        "update:visible": function ($event) {
          _vm.showedit = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.tabindex,
            callback: function ($$v) {
              _vm.tabindex = $$v
            },
            expression: "tabindex",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "平台主推", name: "0" } }),
          _c("el-tab-pane", { attrs: { label: "近期上线", name: "1" } }),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, inline: "" },
        },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { type: "text", placeholder: "产品名称" },
                model: {
                  value: _vm.form.goods_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "goods_name", $$v)
                  },
                  expression: "form.goods_name",
                },
              }),
            ],
            1
          ),
          _vm.form.mall_id == ""
            ? _c(
                "el-form-item",
                { attrs: { label: "一级品类" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "一级分类" },
                      on: { change: _vm.handlercat },
                      model: {
                        value: _vm.form.one_cate,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "one_cate", $$v)
                        },
                        expression: "form.one_cate",
                      },
                    },
                    _vm._l(_vm.cateselect, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.name, value: list.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.mall_id == ""
            ? _c(
                "el-form-item",
                { attrs: { label: "二级品类" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "二级分类" },
                      on: { change: _vm.handlertwo },
                      model: {
                        value: _vm.form.two_cate,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "two_cate", $$v)
                        },
                        expression: "form.two_cate",
                      },
                    },
                    _vm._l(_vm.twocatselect, function (list) {
                      return _c("el-option", {
                        key: list.id,
                        attrs: { label: list.name, value: list.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "招商区域" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "请选择招商区域" },
                  model: {
                    value: _vm.form.province,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "province", $$v)
                    },
                    expression: "form.province",
                  },
                },
                _vm._l(_vm.provincelist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerlist()
                    },
                  },
                },
                [_vm._v(" 查询 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c(
            "el-table",
            {
              ref: "ElTable",
              staticStyle: { flex: "1" },
              attrs: {
                border: "",
                data: _vm.tabledata,
                "row-key": _vm.getRowKey,
                "max-height": "500px",
              },
              on: { "current-change": _vm.setSelectRows },
            },
            _vm._l(_vm.columns, function (item, tableIndex) {
              return _c("el-table-column", {
                key: tableIndex,
                attrs: {
                  align: "center",
                  label: item.label,
                  prop: item.prop,
                  width: item.width,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          item.label == "产品图片"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: {
                                        width: "80px",
                                        height: "80px",
                                      },
                                      attrs: {
                                        "preview-src-list": [row.img],
                                        src: row.img,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "placeholder" },
                                          slot: "placeholder",
                                        },
                                        [
                                          _vm._v(" 加载中 "),
                                          _c("span", { staticClass: "dot" }, [
                                            _vm._v("..."),
                                          ]),
                                        ]
                                      ),
                                      _c("div", {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : item.label == "用户状态"
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.is_delaer == 1 ? "已认证" : "未认证"
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _vm.selectlist.length > 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "240px",
                    "margin-left": "10px",
                    padding: "0 15px",
                    border: "1px solid #ccc",
                  },
                },
                [
                  _c("p", { staticStyle: { cursor: "default" } }, [
                    _vm._v("已选择"),
                  ]),
                  _vm._l(_vm.selectlist, function (list, index) {
                    return _c(
                      "div",
                      { key: index, staticStyle: { "line-height": "25px" } },
                      [
                        _c("span", [_vm._v(_vm._s(list.goods_name))]),
                        _c(
                          "span",
                          {
                            staticStyle: { "margin-left": "8px" },
                            on: {
                              click: function ($event) {
                                return _vm.closeselect(list, index)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-delete",
                              staticStyle: { "font-size": "16px" },
                            }),
                          ]
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onclose } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }