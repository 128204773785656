var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        top: "5vh",
        visible: _vm.showEditDialog,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showEditDialog = $event
        },
        close: _vm.onclose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "right",
            "label-width": "140px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "80%" },
                attrs: { placeholder: "请输入标题" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "类型", prop: "label_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择活动类型" },
                  model: {
                    value: _vm.form.label_id,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "label_id",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.label_id",
                  },
                },
                _vm._l(_vm.typeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "封面图", prop: "cover_img" } },
            [
              _c("upload-img", {
                ref: "cover_img",
                attrs: {
                  "info-text": "建议尺寸：750*350",
                  limit: 10,
                  "max-size": 100,
                },
                on: {
                  getImgs: function ($event) {
                    return _vm.getGoods_imgImgs($event, "cover_img")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动介绍" } },
            [
              _c(
                "div",
                _vm._l(_vm.introductions, function (item, index) {
                  return _c(
                    "p",
                    { key: index },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            width: "250px",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.editintroduction(item, index)
                            },
                          },
                        },
                        [_vm._v(" 编辑 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteintroduction(item, index)
                            },
                          },
                        },
                        [_vm._v(" 删除 ")]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addIntroduction },
                },
                [_vm._v(" 添加活动介绍 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "精选路线" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.is_optimal_route,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_optimal_route", $$v)
                    },
                    expression: "form.is_optimal_route",
                  },
                },
                [_vm._v("开启")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.form.is_optimal_route,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_optimal_route", $$v)
                    },
                    expression: "form.is_optimal_route",
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动日期" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "350px" },
                attrs: {
                  "end-placeholder": "活动结束日期",
                  format: "yyyy-MM-dd",
                  "start-placeholder": "活动开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否开启报名" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.is_open_apply,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_open_apply", $$v)
                    },
                    expression: "form.is_open_apply",
                  },
                },
                [_vm._v("开启")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.form.is_open_apply,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_open_apply", $$v)
                    },
                    expression: "form.is_open_apply",
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "绑定报名" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "350px" },
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "报名ID",
                  },
                  model: {
                    value: _vm.form.event_id,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "event_id",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.event_id",
                  },
                },
                _vm._l(_vm.eventOptions, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.title, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否显示报名倒计时" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.is_count_down,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_count_down", $$v)
                    },
                    expression: "form.is_count_down",
                  },
                },
                [_vm._v("显示")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.form.is_count_down,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_count_down", $$v)
                    },
                    expression: "form.is_count_down",
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否配置客服" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.is_config_customer,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_config_customer", $$v)
                    },
                    expression: "form.is_config_customer",
                  },
                },
                [_vm._v("是")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.form.is_config_customer,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_config_customer", $$v)
                    },
                    expression: "form.is_config_customer",
                  },
                },
                [_vm._v("否")]
              ),
            ],
            1
          ),
          _vm.form.is_config_customer == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "客服入口图标", prop: "customer_img" } },
                [
                  _c("upload-img", {
                    ref: "customer_img",
                    attrs: {
                      "info-text": "建议尺寸：136*136",
                      limit: 1,
                      "max-size": 100,
                    },
                    on: {
                      getImgs: function ($event) {
                        return _vm.getGoods_imgImg($event, "customer_img")
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.is_config_customer == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "活动负责人", prop: "duty_person" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择活动负责人",
                      },
                      model: {
                        value: _vm.form.duty_person,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "duty_person",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.duty_person",
                      },
                    },
                    _vm._l(_vm.dutyOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.is_config_customer == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "是否显示专属客服" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.form.is_exclusive_customer,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_exclusive_customer", $$v)
                        },
                        expression: "form.is_exclusive_customer",
                      },
                    },
                    [_vm._v("是")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.form.is_exclusive_customer,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_exclusive_customer", $$v)
                        },
                        expression: "form.is_exclusive_customer",
                      },
                    },
                    [_vm._v("否")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input", {
                staticStyle: { width: "100px" },
                attrs: { min: 1, placeholder: "请输入", type: "number" },
                model: {
                  value: _vm.form.sort_order,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort_order", $$v)
                  },
                  expression: "form.sort_order",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否热门" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.is_hot,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_hot", $$v)
                    },
                    expression: "form.is_hot",
                  },
                },
                [_vm._v("是")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.form.is_hot,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_hot", $$v)
                    },
                    expression: "form.is_hot",
                  },
                },
                [_vm._v("否")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否主页展示" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.is_home_show,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_home_show", $$v)
                    },
                    expression: "form.is_home_show",
                  },
                },
                [_vm._v("是")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.form.is_home_show,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_home_show", $$v)
                    },
                    expression: "form.is_home_show",
                  },
                },
                [_vm._v("否")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("启用")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("禁用")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onclose } }, [_vm._v("取消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlerSave } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c("introduction", {
        ref: "introduction",
        on: { getlist: _vm.getlist, getdata: _vm.getdata },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }