<template>
  <el-dialog title="邀请" :visible.sync="show" width="1200px">
    <div>
      <el-form ref="form" inline label-suffix=":" :model="form">
        <el-form-item>
          <el-input
            v-model="form.name"
            clearable
            placeholder="姓名"
            style="width: 110px"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="form.company"
            clearable
            placeholder="公司名称"
            style="width: 110px"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.province_id"
            clearable
            placeholder="省份"
            style="width: 120px"
            @change="handlerprovince"
          >
            <el-option
              v-for="list in provinceSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.city_id"
            clearable
            placeholder="城市"
            style="width: 120px"
            @change="handlercity"
          >
            <el-option
              v-for="list in citySelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-select
            v-model="form.area_id"
            clearable
            placeholder="地区"
            style="width: 120px"
          >
            <el-option
              v-for="list in districtSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
<!--        <el-form-item>
          <el-select
            v-model="form.status"
            clearable
            placeholder="状态"
            style="width: 100px"
          >
            <el-option
              v-for="list in statusSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>-->
        <el-form-item>
          <el-select
            v-model="form.channel_id"
            clearable
            placeholder="渠道"
            style="width: 120px"
          >
            <el-option
              v-for="list in channelSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.cate_id"
            clearable
            placeholder="品类"
            style="width: 120px"
          >
            <el-option
              v-for="list in cateSelect"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="time"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerExport">导 出</el-button>
        </el-form-item>
      </el-form>

      <el-table border :data="tabledata" style="width: 100%">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          align="center"
          :label="item.label"
          :prop="item.prop"
          :sortable="item.sort"
          :width="item.width"
        >
          <template #default="{ row }">
            <div v-if="item.label == '展示图'">
              <el-image
                :preview-src-list="[row[item.prop]]"
                :src="row[item.prop]"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div v-else-if="item.label == '相册'">
              <el-image
                :preview-src-list="[row[item.prop]]"
                :src="row[item.prop]"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div v-else-if="item.label == '店铺名称'">
              {{ row[item.prop].mall_name }}
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="form.page"
        :layout="layout"
        :page-size="form.limit"
        style="text-align: center; margin-top: 10px"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from '@/api/request'
import * as excel from "@/utils/excel";

export default {
  name: 'yaoqing',
  data() {
    return {
      show: false,
      form: {
        page: 1,
        limit: 10,
        mall_id: '',
        name: '',
        province_id: '',
        city_id: '',
        area_id: '',
        company: '',
        channel_id: '',
        cate_id: '',
        type:'1',
      },
      /*statusSelect: [
        {
          id: 1,
          name: '已查看',
        },
        {
          id: 2,
          name: '已放弃',
        },
        {
          id: 0,
          name: '未处理',
        },
        {
          id: -1,
          name: '全部',
        },
      ],*/
      layout: 'total, sizes, prev, pager, next, jumper',
      tabledata: [],
      time:[],
      columns: [
        {
          label: '姓名',
          prop: 'name',
          width: '',
          sort: false,
        },
        {
          label: '职务',
          prop: 'job',
          width: '',
          sort: false,
        },
        {
          label: '公司名称',
          prop: 'company',
          width: '',
          sort: false,
        },
        {
          label: '电话',
          prop: 'phone',
          width: '',
          sort: false,
        },
        {
          label: '区域',
          prop: 'region',
          width: '',
          sort: false,
        },
        {
          label: '主营渠道',
          prop: 'channel',
          width: '',
          sort: false,
        },
        {
          label: '关注品类',
          prop: 'category',
          width: '',
          sort: false,
        },
        {
          label: '代理品牌',
          prop: 'brand',
          width: '',
          sort: false,
        },
      ],
      total: 0,
      provinceSelect: [],
      citySelect: [],
      districtSelect: [],
      channelSelect: [],
      cateSelect: [],
    }
  },
  watch:{
    time(v){
      if (v) {
        this.form.start_time = v[0]
        this.form.end_time = v[1]
      } else {
        this.form.start_time = ""
        this.form.end_time = ""
      }
    },
    show(v){
      if(!v){
        this.form={
          page:1,
          limit:10,
          mall_id:'',
          name:'',
          province_id:'',
          city_id:'',
          area_id:'',
          company:'',
          channel_id:'',
          cate_id:'',
          type:'1',
        }
      }
    }
  },
  mounted() {
    getAction('/user/index/area', { pid: 0 }).then((res) => {
      this.provinceSelect = res.data
    })
    getAction('/user/index/channel').then((res) => {
      this.channelSelect = res.data
    })
    getAction('/user/index/cate', { pid: 0 }).then((res) => {
      this.cateSelect = res.data
    })
  },
  methods: {
    handlerInquire() {
      this.form.page = 1
      this.handlerlist()
    },
    handlerinfo(mallId){
      this.form.mall_id=mallId
      this.handlerlist()
      this.show=true
    },
    handlerExport(){
      getAction('/mall/setmeal/invite-lists-export',this.form).then(res=>{
        excel.export_json_to_excel({
          header: res.data.header,
          data: res.data.data,
          filename: res.data.name,
        })
      })
    },
    handlerprovince(e) {
      getAction('/user/index/area', { pid: e }).then((res) => {
        this.citySelect = res.data
        this.form.city_id = ''
        this.form.area_id = ''
        this.districtSelect = []
      })
    },
    handlercity(e) {
      getAction('/user/index/area', { pid: e }).then((res) => {
        this.districtSelect = res.data
        this.form.area_id = ''
      })
    },
    handlerlist() {
      getAction('/mall/setmeal/invite-lists', this.form).then((res) => {
        this.tabledata = res.data
        this.total = Number(res.totalCount)
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.handlerlist()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.handlerlist()
    },
  },
}
</script>

<style scoped></style>
