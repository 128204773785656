var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "广告详情",
            visible: _vm.dialogVisible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "position_name",
                      label: "广告位",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "状态", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "advert_exposure_num",
                      label: "已完成（真实）",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "advert_exposure_virtual_num",
                      label: "已完成（虚拟）",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "advert_exposure_num",
                      label: "已完成（总计）",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.advert_exposure_num +
                                    scope.row.advert_exposure_virtual_num
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "advert_max_exposure_num",
                      label: "总量",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "type", label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.status == 1 && scope.row.status != "已完成"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlertask(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 增加虚拟曝光量 ")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("tasklog", { ref: "taskLog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }