<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    top="5vh"
    :visible.sync="showEditDialog"
    width="70%"
  >
    <div>
      <el-form ref="form" inline :model="form">
        <el-form-item>
          <el-input
            v-model.trim="form.keywords"
            placeholder="标题搜索"
            style="width: 200px"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model.trim="form.type"
            placeholder="请选择路线类型"
            clearable
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="form.status" clearable>
            <el-option label="禁用" value="0" />
            <el-option label="启用" value="1" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getData">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerEdit()">添加路线</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="table"
        v-loading="loading"
        border
        :data="tabledata"
        row-key="user_id"
      >
        <el-table-column align="center" label="ID" prop="id" width="100" />
        <el-table-column align="center" label="图片" prop="img" width="150">
          <template #default="{ row }">
            <!-- <span v-for="(list, index) in row.prop" :key="index">
              <el-image
                :preview-src-list="[list]"
                :src="list"
                style="width: 100px; height: 100px"
              >
                <div slot="placeholder" class="image-slot">
                  加载中
                  <span class="dot">...</span>
                </div>
              </el-image>
            </span> -->

            <el-image :src="row.img" style="width: 100px; height: auto">
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="标题" prop="title" />
        <el-table-column align="center" label="类型" prop="type">
          <template #default="{ row }">
            {{ row.type == 1 ? '精选企业' : '精选商业' }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="排序" prop="sort_order" />
        <el-table-column align="center" label="状态" prop="status">
          <template #default="{ row }">
            {{ row.status == 1 ? '开启' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
          prop="id"
          width="200"
          fixed="right"
        >
          <template #default="{ row }">
            <el-button
              style="margin: 10px"
              type="text"
              @click.native.prevent="handlerEdit(row)"
            >
              编辑
            </el-button>
            <el-popconfirm title="确认删除吗？" @confirm="handlerDel(row)">
              <el-button slot="reference" size="small" type="text">
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <div slot="footer" class="dialog-footer">
      <el-button @click="showEditDialog = false">取消</el-button>
      <el-button type="primary" @click="handlerSave">确定</el-button>
    </div> -->
    <lineedit ref="lineedit" @getlist="getlist" />
  </el-dialog>
</template>
<script>
  import lineedit from './lineedit'
  import { getAction, postAction } from '@/api/request'
  export default {
    components: { lineedit },
    data() {
      return {
        title: '路线管理',
        showEditDialog: false,
        loading: false,
        tabledata: [],
        form: {
          keywords: '',
          type: '',
          status: '',
        },
        time: [],
        typeOptions: [
          {
            id: 1,
            name: '精选企业',
          },
          {
            id: 2,
            name: '精选商业',
          },
        ],
      }
    },
    methods: {
      getData() {
        this.getlist()
      },
      //删除路线
      handlerDel(row) {
        getAction('/api/study/study-trip/route-del', { id: row.id }).then(
          (res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              this.getlist()
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      //获取列表数据
      getlist() {
        getAction('/api/study/study-trip/route-list', this.form).then((res) => {
          if (res.code == 0) {
            this.tabledata = res.data
          }
        })
      },
      handlerEdit(row) {
        this.$refs.lineedit.showEditDialog = true
        if (row) {
          this.$refs.lineedit.handlerinfo(row)
        }
      },
      handlerSave() {
        postAction('/api/study/study-trip/route-save', this.form).then(
          (res) => {}
        )
      },
    },
  }
</script>
