var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-table",
        {
          ref: "ElTable",
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "55",
              align: "center",
              "reserve-selection": true,
            },
          }),
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.label == "来源"
                          ? _c("div", [_vm._v("头条拉取")])
                          : item.label == "拉取状态"
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row[item.prop] == 1 ? "已拉取" : "未拉取"
                                  ) +
                                  " "
                              ),
                            ])
                          : item.label == "封面图"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-image",
                                  {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      "preview-src-list": [row[item.prop]],
                                      src: row[item.prop],
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "placeholder" },
                                        slot: "placeholder",
                                      },
                                      [
                                        _vm._v(" 加载中 "),
                                        _c("span", { staticClass: "dot" }, [
                                          _vm._v("..."),
                                        ]),
                                      ]
                                    ),
                                    _c("div", {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "确认要恢复吗？" },
                        on: {
                          confirm: function ($event) {
                            return _vm.deleteRow(row)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "text",
                            },
                            slot: "reference",
                          },
                          [_vm._v(" 恢复 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }