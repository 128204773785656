<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    top="5vh"
    :visible.sync="showEditDialog"
    width="40%"
    append-to-body
    @close="onclose"
  >
    <el-form
      ref="form"
      label-position="right"
      label-width="120px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="类型" prop="type">
        <el-select
          v-model.trim="form.type"
          clearable
          placeholder="请选择路线类型"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-input
          v-model="form.title"
          placeholder="请输入标题"
          style="width: 80%"
        />
      </el-form-item>
      <el-form-item label="图片" prop="img">
        <upload-img
          ref="img"
          :info-text="'建议尺寸：136*136'"
          :limit="1"
          :max-size="100"
          @getImgs="getGoods_imgImg($event, 'img')"
        />
      </el-form-item>
      <el-form-item label="排序">
        <el-input
          v-model="form.sort_order"
          :min="1"
          placeholder="请输入"
          style="width: 100px"
          type="number"
        />
      </el-form-item>
      <el-form-item label="状态">
        <el-radio v-model="form.status" label="1">启用</el-radio>
        <el-radio v-model="form.status" label="0">禁用</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onclose()">取消</el-button>
      <el-button type="primary" @click="handlerSave">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import UploadImg from '@/components/uploadImg.vue'
  import { getAction, postAction } from '@/api/request'
  export default {
    components: { UploadImg },
    data() {
      return {
        title: '添加路线',
        showEditDialog: false,
        typeOptions: [
          {
            id: '1',
            name: '精选企业',
          },
          {
            id: '2',
            name: '精选商业',
          },
        ],
        form: {
          title: '',
          img: '',
          type: '',
          sort_order: '',
          status: '1',
        },
        rules: {
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          type: [
            { required: true, message: '请选择路线类型', trigger: 'change' },
          ],
          img: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        },
      }
    },
    methods: {
      onclose() {
        this.showEditDialog = false
        this.form = {
          title: '',
          img: '',
          type: '',
          sort_order: '',
          status: '1',
        }
        this.closeimg('img')
      },
      handlerinfo(row) {
        console.log('----,', row)
        this.form = row
        this.$nextTick(() => {
          this.setImg(row.img, 'img')
        })
      },
      handlerSave() {
        let params = JSON.parse(JSON.stringify(this.form))
        this.$refs.form.validate((valid) => {
          if (valid) {
            postAction('/api/study/study-trip/route-save', params).then(
              (res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg)
                  this.onclose()
                  this.$emit('getlist')
                } else {
                  this.$message.erro(res.msg)
                }
              }
            )
          }
        })
      },
      getGoods_imgImg(v, prop) {
        if (v[0]) {
          this.form[prop] = v.join(',')
        } else {
          this.form[prop] = ''
        }
      },
      getGoods_imgImgs(v, prop) {
        if (v[0]) {
          this.form[prop] = v
        } else {
          this.form[prop] = ''
        }
      },
      setImg(v, prop) {
        console.log('复制，', prop, v)
        if (v) this.$refs[prop].img = [v]
      },
      setImgArray(v, prop) {
        console.log('复制，', prop, v)
        if (v[0] != '') this.$refs[prop].img = v
      },
      closeimg(prop) {
        this.$refs[prop].img = []
      },
    },
  }
</script>
