var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "activelist-list-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "el-form",
            {
              attrs: { inline: true, model: _vm.queryForm },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleConfig },
                    },
                    [_vm._v(" 公共配置 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.queryForm.type,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.queryForm,
                            "type",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "queryForm.type",
                      },
                    },
                    _vm._l(_vm.typeOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.queryForm.door,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.queryForm,
                            "door",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "queryForm.door",
                      },
                    },
                    _vm._l(_vm.doorOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-search", type: "primary" },
                      on: { click: _vm.queryData },
                    },
                    [_vm._v(" 查询 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "table",
          attrs: { border: "", data: _vm.list },
          on: { "selection-change": _vm.setSelectRows },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "ID",
              prop: "id",
              "show-overflow-tooltip": "",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "类型", prop: "type" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.type == 1
                      ? _c("span", [_vm._v("品类弹窗")])
                      : row.type == 2
                      ? _c("span", [_vm._v("新品弹窗")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "品类", prop: "cat_pname" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.cat_pname
                      ? _c("span", [_vm._v(_vm._s(row.cat_pname))])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "福利弹框图片", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "180px", height: "30px" },
                        attrs: { src: scope.row.welfare_pic },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "placeholder" },
                            slot: "placeholder",
                          },
                          [
                            _vm._v(" 加载中 "),
                            _c("span", { staticClass: "dot" }, [_vm._v("...")]),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "领取弹窗图片", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "60px", height: "60px" },
                        attrs: { src: scope.row.receive_pic },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "placeholder" },
                            slot: "placeholder",
                          },
                          [
                            _vm._v(" 加载中 "),
                            _c("span", { staticClass: "dot" }, [_vm._v("...")]),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "浏览时长",
              prop: "views_time",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "弹出次数",
              prop: "popup_num",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "弹窗开关",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.door == 1
                      ? _c("span", [_vm._v("开启")])
                      : _c("span", [_vm._v("关闭")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", fixed: "right", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
      _c("config", { ref: "config", on: { "fetch-data": _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }