<template>
  <div class="index-container">
    <el-form ref="form" :model="form">
      <el-form-item label="顶部Banner模板" label-width="120px">
        <el-radio v-model="form.banner_model" label="1">列表展示</el-radio>
        <el-radio v-model="form.banner_model" label="2">轮播</el-radio>
      </el-form-item>
      <el-form-item label="最新活动模板" label-width="120px">
        <el-radio v-model="form.event_model" label="1">列表展示</el-radio>
        <el-radio v-model="form.event_model" label="2">标签切换</el-radio>
      </el-form-item>
      <el-form-item
        v-if="form.event_model == 1"
        label="标题"
        label-width="120px"
      >
        <el-input
          v-model.trim="form.event_title"
          placeholder="最新活动"
          style="width: 250px"
        />
      </el-form-item>
      <el-form-item
        v-if="form.event_model == 2"
        label="标签"
        label-width="120px"
      >
        <el-form-item v-for="(item, index) in form.event_label" :key="index">
          <el-input
            v-model.trim="item.title"
            placeholder="线下研修"
            style="width: 250px"
          />
          <span v-show="index > 0" class="btnicon min" @click="moveUp(index)">
            <i
              class="el-icon-top"
              style="font-size: 18px; font-weight: bold; margin-right: 5px"
            ></i>
          </span>
          <span
            v-show="index < form.event_label.length - 1"
            class="btnicon min"
            @click="moveDown(index)"
          >
            <i
              class="el-icon-bottom"
              style="font-size: 18px; font-weight: bold"
            ></i>
          </span>
          <el-switch
            style="margin-left: 20px"
            v-model="item.status"
            active-text="启用"
            inactive-text="禁用"
            active-value="1"
            inactive-value="2"
          ></el-switch>
        </el-form-item>
        <!-- <el-form-item>
          <el-input
            v-model.trim="form.title"
            placeholder="区域对接"
            style="width: 250px"
          />
          <el-input
            v-model.trim="form.title"
            type="number"
            placeholder=""
            style="width: 80px; margin-left: 20px"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model.trim="form.title"
            placeholder="区域活动"
            style="width: 250px"
          />
          <el-input
            v-model.trim="form.title"
            type="number"
            placeholder=""
            style="width: 80px; margin-left: 20px"
          />
        </el-form-item> -->
      </el-form-item>
    </el-form>
    <div style="margin-left: 120px">
      <el-button type="primary" @click="onSave">设置</el-button>
      <el-button type="primary" @click="onPreview">预览</el-button>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      top="5vh"
      :visible.sync="showPreview"
      width="40%"
      append-to-body
    >
      <img
        src="https://res.httoutiao.com/yunjiaohui/images/houtai/pic.jpg"
        alt=""
        style="width: 100%; display: inline-block"
      />
    </el-dialog>
  </div>
</template>
<script>
  import { getAction } from '@/api/request'
  export default {
    data() {
      return {
        showPreview: false,
        form: {
          banner_model: '1', //1列表 2轮播
          event_model: '1', //1列表 2标签
          event_title: '', //活动标题
          event_label: [
            {
              id: '',
              title: '线下研修',
              status: '1', //0关闭 1开启
            },
            {
              id: '',
              title: '区域对接',
              status: '1', //0关闭 1开启
            },
            {
              id: '',
              title: '区域活动',
              status: '1', //0关闭 1开启
            },
          ],
        },
      }
    },
    mounted() {
      this.getdata()
    },
    methods: {
      onSave() {
        let params = JSON.parse(JSON.stringify(this.form))
        params.event_label = JSON.stringify(this.form.event_label)
        getAction('/api/study/study-trip/page-config-save', params).then(
          (res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      onPreview() {
        this.showPreview = true
      },
      getdata() {
        getAction('/api/study/study-trip/page-config').then((res) => {
          if (res.code == 0) {
            this.form = res.data
          }
        })
      },
      // 上移
      moveUp(i) {
        let temp = this.form.event_label[i]
        this.$set(this.form.event_label, i, this.form.event_label[i - 1])
        this.$set(this.form.event_label, i - 1, temp)
      },
      // 下移
      moveDown(i) {
        let temp = this.form.event_label[i]
        this.$set(this.form.event_label, i, this.form.event_label[i + 1])
        this.$set(this.form.event_label, i + 1, temp)
      },
    },
  }
</script>
