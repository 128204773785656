var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.dialogVisible, width: "60%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlerExporttow } },
            [_vm._v("导出")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableloading,
                  expression: "tableloading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tabledata },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "d", label: "日期", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "mall_id", label: "店铺ID", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "mall_name",
                  label: "店铺名称",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "all_pv", label: "访问页面数", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "pv", label: "真实访问页面数", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(Number(row.pv) + Number(row.goods_pv))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "uv",
                  label: "访问产品/店铺用户数",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(Number(row.uv) + Number(row.goods_uv))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "cat_root_name",
                  label: "一级品类",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "cat_name", label: "二级品类", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "meal_title",
                  label: "当前套餐",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center", "margin-top": "10px" },
            attrs: {
              "current-page": _vm.mallform.page,
              layout: _vm.layout,
              "page-size": _vm.mallform.limit,
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": (e) => {
                _vm.handleCurrentChange(e)
              },
              "size-change": (e) => {
                _vm.handleSizeChange(e)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }