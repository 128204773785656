var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "图片配置",
        top: "5vh",
        visible: _vm.showEditDialog,
        width: "40%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showEditDialog = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "right",
            "label-width": "120px",
            model: _vm.form,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "图片类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择图片类型" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "type",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.typeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图片标题" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: { placeholder: "请输入图片标题" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "跳转类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.form.jump_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "jump_type", $$v)
                    },
                    expression: "form.jump_type",
                  },
                },
                _vm._l(_vm.jumpOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.jump_type != 0
            ? _c(
                "el-form-item",
                { attrs: { label: "跳转链接" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入链接" },
                    model: {
                      value: _vm.form.jump_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "jump_url", $$v)
                      },
                      expression: "form.jump_url",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type == 1
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "轮播图片", prop: "img" } },
                    [
                      _c("upload-img", {
                        ref: "pic",
                        attrs: {
                          "info-text": "尺寸：750*300",
                          limit: 1,
                          "max-size": 100,
                        },
                        on: {
                          getImgs: function ($event) {
                            return _vm.getGoods_imgImg($event, "img")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.form.type == 2
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "宣传海报" } },
                    [
                      _c("upload-img", {
                        ref: "pic",
                        attrs: {
                          "info-text": "宽度690",
                          limit: 1,
                          "max-size": 100,
                        },
                        on: {
                          getImgs: function ($event) {
                            return _vm.getGoods_imgImg($event, "img")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.form.type == 3
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "福利图片" } },
                    [
                      _c("upload-img", {
                        ref: "pic",
                        attrs: {
                          "info-text": "尺寸750*260",
                          limit: 1,
                          "max-size": 100,
                        },
                        on: {
                          getImgs: function ($event) {
                            return _vm.getGoods_imgImg($event, "img")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.form.type == 4
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "顶部背景图" } },
                    [
                      _c("upload-img", {
                        ref: "pic",
                        attrs: {
                          "info-text": "尺寸750*400",
                          limit: 1,
                          "max-size": 100,
                        },
                        on: {
                          getImgs: function ($event) {
                            return _vm.getGoods_imgImg($event, "img")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "背景色值" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.form.color,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "color", $$v)
                          },
                          expression: "form.color",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.form.type == 6
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "趋势报告" } },
                    [
                      _c("upload-img", {
                        ref: "pic",
                        attrs: {
                          "info-text": "尺寸750*400",
                          limit: 1,
                          "max-size": 100,
                        },
                        on: {
                          getImgs: function ($event) {
                            return _vm.getGoods_imgImg($event, "img")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.form.type == 5
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "线下研修" } },
                    [
                      _c("upload-img", {
                        ref: "pic",
                        attrs: {
                          "info-text": "尺寸750*400",
                          limit: 1,
                          "max-size": 100,
                        },
                        on: {
                          getImgs: function ($event) {
                            return _vm.getGoods_imgImg($event, "img")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type != 3 && _vm.form.type != 4
            ? _c(
                "el-form-item",
                { attrs: { label: "图片排序" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    attrs: { min: 1, placeholder: "请输入", type: "number" },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type == 6
            ? _c(
                "el-form-item",
                { attrs: { label: "是否置顶" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.form.is_top,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_top", $$v)
                        },
                        expression: "form.is_top",
                      },
                    },
                    [_vm._v("是")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.form.is_top,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_top", $$v)
                        },
                        expression: "form.is_top",
                      },
                    },
                    [_vm._v("否")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "图片状态" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("启用")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("禁用")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showEditDialog = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlerSave } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }