<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input
          v-model.trim="form.title"
          placeholder="标题"
          style="width: 250px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model.trim="form.jump_type"
          placeholder="二维码类型"
          clearable
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.status" clearable>
          <el-option label="禁用" value="0" />
          <el-option label="启用" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerEdit()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="table"
      v-loading="loading"
      border
      :data="tabledata"
      row-key="user_id"
    >
      <el-table-column align="center" label="ID" prop="id" width="100" />
      <el-table-column align="center" label="标题" prop="title" />
      <el-table-column align="center" label="图片" prop="icon_img" width="150">
        <template #default="{ row }">
          <el-image
            v-if="row.type == 5 || row.type == 6"
            :src="row.img"
            style="width: 120px; height: auto"
          >
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
          <el-image v-else :src="row.img" style="width: 100px; height: auto">
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="二维码类型"
        prop="jump_type_name"
      />
      <el-table-column
        align="center"
        label="排序"
        prop="sort_order"
        width="150"
      />
      <el-table-column align="center" label="状态" prop="status" width="150">
        <template #default="{ row }">
          {{ row.status == 0 ? '禁用' : '开启' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        prop="created"
        width="150"
      />
      <el-table-column
        align="center"
        label="操作"
        prop="id"
        width="200"
        fixed="right"
      >
        <template #default="{ row }">
          <el-button
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            style="margin: 10px"
            type="text"
            @click.native.prevent="handlerDel(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @getlist="getData()" />
  </div>
</template>
<script>
  import { delBanner, getAction } from '@/api/request'
  import edit from './components/posteredit'
  export default {
    name: 'BanLog',
    components: { edit },
    data() {
      return {
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        loading: false,
        tabledata: [],
        form: {
          page: 1,
          limit: 10,
          status: '1',
          title: '',
          jump_type: '',
        },
        typeOptions: [
          // {
          //   value: 0,
          //   label: '全部图片',
          // },
          {
            value: 1,
            label: '企微二维码',
          },
          {
            value: 2,
            label: '华糖会员首页',
          },
          {
            value: 3,
            label: '华糖会员-开通页',
          },
          {
            value: 13,
            label: '数字刊咨讯-开通页',
          },
          {
            value: 4,
            label: '小课堂-首页',
          },
          {
            value: 11,
            label: '小课堂-课程详情',
          },
          {
            value: 5,
            label: '线下研修-首页',
          },
          {
            value: 12,
            label: '线下研修-活动详情',
          },
          {
            value: 6,
            label: '数字刊-首页',
          },
          {
            value: 7,
            label: '数字刊-期刊详情',
          },
          {
            value: 8,
            label: '数字刊-文章详情',
          },
          {
            value: 9,
            label: '数字刊-视频详情',
          },
          {
            value: 10,
            label: '数字刊-期刊主页',
          },
        ],
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      handlerEdit(row) {
        this.$refs.edit.showEditDialog = true
        if (row) {
          this.$refs.edit.form = JSON.parse(JSON.stringify(row))
          this.$refs.edit.handlerinfo(row)
          this.$refs.edit.title = '编辑海报'
        } else {
          this.$refs.edit.title = '新增海报'
        }
      },
      handlerDel(row) {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            delBanner('/user/business-club/del-poster', { id: row.id }).then(
              (res) => {
                if (res.request_code == 200) {
                  this.$message.success(res.msg)
                  this.getData()
                } else {
                  this.$message.error('删除失败')
                }
              }
            )
          })
          .catch(() => {})
      },
      getData() {
        getAction('/user/business-club/poster-list', this.form).then((res) => {
          console.log('获取数据', res)
          this.tabledata = res.data
          this.total = res.totalCount
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.getData()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.getData()
      },
    },
  }
</script>
