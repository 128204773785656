var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { inline: "", model: _vm.form } },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "店铺名称" },
                model: {
                  value: _vm.form.mall_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mall_name", $$v)
                  },
                  expression: "form.mall_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "公司名称" },
                model: {
                  value: _vm.form.company,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "company", $$v)
                  },
                  expression: "form.company",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "店铺ID" },
                model: {
                  value: _vm.form.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "id", $$v)
                  },
                  expression: "form.id",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "邀请人" },
                model: {
                  value: _vm.form.inviter_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "inviter_name", $$v)
                  },
                  expression: "form.inviter_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "end-placeholder": "结束通过日期",
                  format: "yyyy-MM-dd",
                  "start-placeholder": "开始通过日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "end-placeholder": "产品通过结束",
                  format: "yyyy-MM-dd",
                  "start-placeholder": "产品通过开始",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.timegoods,
                  callback: function ($$v) {
                    _vm.timegoods = $$v
                  },
                  expression: "timegoods",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "340px" },
                attrs: {
                  "end-placeholder": "浏览结束",
                  format: "yyyy-MM-dd",
                  "start-placeholder": "浏览开始",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.timeplay,
                  callback: function ($$v) {
                    _vm.timeplay = $$v
                  },
                  expression: "timeplay",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-cascader", {
                attrs: {
                  clearable: "",
                  "collapse-tags": true,
                  filterable: "",
                  options: _vm.cateSelect,
                  placeholder: "经营品类",
                  "popper-class": "ssaassd",
                  props: {
                    multiple: true,
                    checkStrictly: true,
                    emitPath: false,
                    value: "id",
                    label: "name",
                  },
                  "show-all-levels": false,
                },
                model: {
                  value: _vm.form.trades,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "trades", $$v)
                  },
                  expression: "form.trades",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "180px" },
                  attrs: {
                    clearable: "",
                    "collapse-tags": "",
                    multiple: "",
                    placeholder: "当前套餐",
                  },
                  model: {
                    value: _vm.form.pay_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "pay_type", $$v)
                    },
                    expression: "form.pay_type",
                  },
                },
                _vm._l(_vm.mealselect, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.title, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "企业服务人员" },
                model: {
                  value: _vm.form.service_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "service_name", $$v)
                  },
                  expression: "form.service_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "是否有通过产品" },
                  model: {
                    value: _vm.form.is_goods,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_goods", $$v)
                    },
                    expression: "form.is_goods",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "无", value: 0 } }),
                  _c("el-option", { attrs: { label: "有", value: 1 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "是否设置联系人" },
                  model: {
                    value: _vm.form.is_contacts,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_contacts", $$v)
                    },
                    expression: "form.is_contacts",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "否", value: 0 } }),
                  _c("el-option", { attrs: { label: "是", value: 1 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusSelect, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.title, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "export",
                      expression: "'export'",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerExport },
                },
                [_vm._v(" 导出 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tabledata },
        },
        [
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c(
              "el-table-column",
              {
                key: tableIndex,
                attrs: {
                  align: "center",
                  label: item.label,
                  prop: item.prop,
                  width: item.width,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          item.label == "海报背景"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: {
                                        width: "30px",
                                        height: "70px",
                                      },
                                      attrs: {
                                        "preview-src-list": [row[item.prop]],
                                        src: row[item.prop],
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "placeholder" },
                                          slot: "placeholder",
                                        },
                                        [
                                          _vm._v(" 加载中 "),
                                          _c("span", { staticClass: "dot" }, [
                                            _vm._v("..."),
                                          ]),
                                        ]
                                      ),
                                      _c("div", {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : item.label == "海报分类"
                            ? _c("div", [_vm._v(_vm._s(row.type.name))])
                            : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _c("template", { slot: "header" }, [
                  item.label == "总浏览量"
                    ? _c(
                        "div",
                        { attrs: { slot: "header" }, slot: "header" },
                        [
                          _vm._v(" " + _vm._s(item.label) + " "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "总浏览量=真实浏览量+虚拟浏览量+产品访问量"
                                  ),
                                ]
                              ),
                              _c("i", { staticClass: "el-icon-warning" }),
                            ]
                          ),
                        ],
                        1
                      )
                    : item.label == "产品访问量"
                    ? _c(
                        "div",
                        [
                          _vm._v(" " + _vm._s(item.label) + " "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "产品访问量=真实产品访问量+虚拟产品访问量"
                                  ),
                                ]
                              ),
                              _c("i", { staticClass: "el-icon-warning" }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c("div", [_vm._v(" " + _vm._s(item.label) + " ")]),
                ]),
              ],
              2
            )
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "220px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerstatus(row, 1)
                          },
                        },
                      },
                      [_vm._v(" 小程序码 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerstatus(row, 2)
                          },
                        },
                      },
                      [_vm._v(" 裂变码 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.seegoods(row)
                          },
                        },
                      },
                      [_vm._v("查看产品")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "views",
                            expression: "'views'",
                          },
                        ],
                        attrs: { icon: "el-icon-edit", type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.setViews(row)
                          },
                        },
                      },
                      [_vm._v(" 设置浏览量 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.form.page,
          layout: _vm.layout,
          "page-size": _vm.form.limit,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: { center: "", visible: _vm.qrcodeshow, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.qrcodeshow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
              },
            },
            [
              _c("el-image", {
                staticStyle: { width: "180px", height: "180px" },
                attrs: { "preview-src-list": [_vm.qrcode], src: _vm.qrcode },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.qrcodeshow = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("tasklog", { ref: "taskLog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }