<script>
import { getAction } from "@/api/request";
import Matelist from "@/views/pages/datastatis/packagestatis/packagedata/components/matelist.vue";

export default {
  name: "dianhua",
  components: { Matelist },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      tabletow:[],
      sum:0
    }
  },
  methods: {
    async handlerlist(id,mall_id,mall_name,start_time,end_time) {
      console.log(id)
      await getAction("/mall/setmeal/match-items", {
        id: id,
        start_date: start_time,
        end_date: end_time,
      }).then(res => {
        this.sum= res.data.surplus_match_views_num + res.data.phone_call_nums + res.data.goods_order_nums
        this.tableData = [{position_name:'线上匹配',type:res.data.surplus_match_views_num,mall_id:mall_id,mall_name:mall_name},
          {position_name:'电话咨询',type:res.data.phone_call_nums,mall_id:mall_id,mall_name:mall_name},
          {position_name:'样品申请',type:res.data.goods_order_nums,mall_id:mall_id,mall_name:mall_name}]

        this.tabletow = [
          {name:'留言',
          pic:Number(res.data.match_item.message_all),
          liveic:Number(res.data.match_item.message_view),},
          {name:'品类需求',
            pic:Number(res.data.match_item.business_cate_all),
            liveic:Number(res.data.match_item.business_cate_view),},
          {name:'指定店铺',
            pic:Number(res.data.match_item.business_mall_all),
            liveic:Number(res.data.match_item.business_mall_view),},
          {name:'选品方案',
            pic:Number(res.data.match_item.sel_all),
            liveic:Number(res.data.match_item.sel_view),},
          {name:'经销商找品',
            pic:Number(res.data.match_item.find_all),
            liveic:Number(res.data.match_item.find_view),},
        ]
        this.dialogVisible = true
      })
    },
    handlerToRouter(row){

      if(row.position_name=='线上匹配'){
        this.$refs.mate.show=true
        this.$refs.mate.form.mall_id=row.mall_id
        this.$refs.mate.handlerInquire()
      }else if(row.position_name=='电话咨询'){
        this.$router.push({
          path: '/enterprise/agent',
          query: {
            mall_name: row.mall_name,
          }
        })
      }else if(row.position_name=='样品申请'){
         this.$router.push({
         path: '/enterprise/SampleApp',
         query: {
           mall_name: row.mall_name,
         }
        })
      }
    },
  }
};
</script>

<template>
  <div>
    <matelist ref="mate" />
    <el-dialog
      title="线上匹配+电话咨询+样品申请"
      :visible.sync="dialogVisible"
      width="30%">
    <span>
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
          prop="position_name"
          label="广告位"
          align="center">
        </el-table-column>
        <el-table-column
          prop="type"
          label="已完成"
          align="center">
          <template slot-scope="scope" >
            <div style="color: #00a0e9;cursor: pointer" @click="handlerToRouter(scope.row)">
              {{scope.row['type']}}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <h3>总计：{{sum}}</h3>
      <h4>线上匹配明细</h4>
      <el-table :data="tabletow" style="width: 100%" border show-summary>
        <el-table-column
          prop="name"
          label="类型"
          align="center">
        </el-table-column>
        <el-table-column
          prop="pic"
          label="已匹配数量"
          align="center">
        </el-table-column>
        <el-table-column
          prop="liveic"
          label="已查看数量"
          align="center">
        </el-table-column>
      </el-table>
    </span>
    </el-dialog>
  </div>
</template>

<style scoped>

</style>
