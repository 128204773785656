<template>
  <div class="index-container">
    <el-form ref="form" inline :model="form">
      <el-form-item>
        <el-input v-model="form.mall_name" clearable placeholder="店铺名称" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.company" clearable placeholder="公司名称" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.id" clearable placeholder="店铺ID" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.inviter_name" clearable placeholder="邀请人" />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          end-placeholder="结束通过日期"
          format="yyyy-MM-dd"
          start-placeholder="开始通过日期"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="timegoods"
          end-placeholder="产品通过结束"
          format="yyyy-MM-dd"
          start-placeholder="产品通过开始"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="timeplay"
          end-placeholder="浏览结束"
          format="yyyy-MM-dd"
          start-placeholder="浏览开始"
          style="width: 340px"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-cascader
          v-model="form.trades"
          clearable
          :collapse-tags="true"
          filterable
          :options="cateSelect"
          placeholder="经营品类"
          popper-class="ssaassd"
          :props="{
            multiple: true,
            checkStrictly: true,
            emitPath: false,
            value: 'id',
            label: 'name',
          }"
          :show-all-levels="false"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.pay_type"
          clearable
          collapse-tags
          multiple
          placeholder="当前套餐"
          style="width: 180px"
        >
          <el-option
            v-for="(item, index) in mealselect"
            :key="index"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.service_name"
          clearable
          placeholder="企业服务人员"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_goods"
          clearable
          placeholder="是否有通过产品"
          style="width: 140px"
        >
          <el-option label="无" :value="0" />
          <el-option label="有" :value="1" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.is_contacts"
          clearable
          placeholder="是否设置联系人"
          style="width: 140px"
        >
          <el-option label="否" :value="0" />
          <el-option label="是" :value="1" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.status"
          clearable
          placeholder="状态"
          style="width: 120px"
        >
          <el-option
            v-for="(item, index) in statusSelect"
            :key="index"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button v-has="'export'" type="primary" @click="handlerExport">
          导出
        </el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template slot="header">
          <div slot="header" v-if="item.label=='总浏览量'">
            {{item.label}}
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">总浏览量=真实浏览量+虚拟浏览量+产品访问量</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
          <div v-else-if="item.label=='产品访问量'">
            {{item.label}}
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">产品访问量=真实产品访问量+虚拟产品访问量</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </div>
          <div v-else>
            {{item.label}}
          </div>
        </template>
        <template #default="{ row }">
          <div v-if="item.label == '海报背景'">
            <el-image
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop]"
              style="width: 30px; height: 70px"
            >
              <div slot="placeholder" class="image-slot">
                加载中
                <span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div v-else-if="item.label == '海报分类'">{{ row.type.name }}</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220px">
        <template #default="{ row }">
          <el-button type="text" @click="handlerstatus(row, 1)">
            小程序码
          </el-button>
          <el-button type="text" @click="handlerstatus(row, 2)">
            裂变码
          </el-button>
          <el-button type="text" @click="seegoods(row)">查看产品</el-button>
          <el-button
            v-has="'views'"
            icon="el-icon-edit"
            type="text"
            @click.native.prevent="setViews(row)"
          >
            设置浏览量
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.page"
      :layout="layout"
      :page-size="form.limit"
      style="text-align: center; margin-top: 10px"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <el-dialog center :visible.sync="qrcodeshow" width="30%">
      <div style="display: flex; align-items: center; justify-content: center">
        <el-image
          :preview-src-list="[qrcode]"
          :src="qrcode"
          style="width: 180px; height: 180px"
        />
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="qrcodeshow = false">确 定</el-button>
      </span>
    </el-dialog>
    <tasklog ref="taskLog"></tasklog>
  </div>
</template>

<script>
  import { getAction } from '@/api/request'
  import tasklog from './components/tasklog'
  export default {
    name: 'Index',
    components: { tasklog },
    data() {
      return {
        form: {
          mall_name: '',
          company: '',
          inviter_name: '',
          start_check_date: '',
          end_check_date: '',
          page: 1,
          limit: 10,
          trades: [],
          is_goods: '',
          pay_type: [],
          is_contacts: '',
          status: '',
          service_name: '',
        },
        timeplay:[],
        timegoods: [],
        statusSelect: [
          {
            id: 1,
            title: '已审核',
          },
          {
            id: 2,
            title: '待审核',
          },
          {
            id: 3,
            title: '已驳回',
          },
        ],
        qrcodeshow: false,
        qrcode: '',
        time: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        tabledata: [],
        columns: [
          {
            label: '店铺ID',
            prop: 'id',
            width: '',
          },
          {
            label: '店铺名称',
            prop: 'mall_name',
            width: '',
          },

          {
            label: '公司名称',
            prop: 'company_name',
            width: '',
          },
          {
            label: '一级品类',
            prop: 'cat_root_name',
            width: '',
          },
          {
            label: '二级品类',
            prop: 'cat_name',
            width: '',
          },
          {
            label: '状态',
            prop: 'status_txt',
            width: '',
          },
          {
            label: '店铺审核通过时间',
            prop: 'check_time',
            width: '160',
          },
          {
            label: '首款产品通过时间',
            prop: 'g_check_time',
            width: '',
          },
          {
            label: '邀请人',
            prop: 'inviter_name',
            width: '',
          },

          {
            label: '当前套餐',
            prop: 'title',
            width: '',
          },
          {
            label: '企业服务人员',
            prop: 'service_name',
            width: '',
          },
          {
            label: '业务对接人',
            prop: 'staff',
            width: '',
          },
          {
            label: '当前套餐开始时间',
            prop: 'effective_time',
            width: '160',
          },
          {
            label: '当前套餐结束时间',
            prop: 'failure_time',
            width: '160',
          },
          {
            label: '是否有通过产品',
            prop: 'is_goods',
            width: '',
          },
          {
            label: '是否设置联系人',
            prop: 'is_contacts',
            width: '',
          },
          {
            label: '总浏览量',
            prop: 'view_num',
            width: '',
          },
          {
            label: '真实浏览量',
            prop: 'true_view_nums',
            width: '',
          },
          {
            label: '虚拟浏览量',
            prop: 'virtual_view_nums',
            width: '',
          },
          {
            label: '产品访问量',
            prop: 'goods_view_num',
            width: '',
          },
          {
            label: '总关注量',
            prop: 'like_num',
            width: '',
          },
          {
            label: '真实关注量',
            prop: 'true_like_nums',
            width: '',
          },
          {
            label: '虚拟关注量',
            prop: 'virtual_like_num',
            width: '',
          },
          {
            label: '总代理数量',
            prop: 'intention_num',
            width: '',
          },
          {
            label: '平台代理数量',
            prop: 'intention_num_plat',
            width: '',
          },
          {
            label: '留言数量',
            prop: 'comment_num',
            width: '',
          },
          {
            label: '总样品申请',
            prop: 'sample_num',
            width: '',
          },
          {
            label: '平台样品申请',
            prop: 'sample_num_plat',
            width: '',
          },
        ],
        staffSelect: [],
        cateSelect: [],
        mealselect: [],
        total: 0,
      }
    },
    watch: {
      timegoods(v) {
        if (v) {
          this.form.start_check_goods_date = v[0]
          this.form.end_check_goods_date = v[1]
        } else {
          this.form.start_check_goods_date = ''
          this.form.end_check_goods_date = ''
        }
      },
      timeplay(v) {
        if (v) {
          this.form.start_date = v[0]
          this.form.end_date = v[1]
        } else {
          this.form.start_date = ''
          this.form.end_date = ''
        }
      },
      time(v) {
        if (v) {
          this.form.start_check_date = v[0]
          this.form.end_check_date = v[1]
        } else {
          this.form.start_check_date = ''
          this.form.end_check_date = ''
        }
      },
    },
    async mounted() {
      const cate = await getAction('/user/index/cate-tree')
      this.cateSelect = cate.data
      const meal = await getAction('/mall/index/meal')
      this.mealselect = meal.data
      this.mealselect.push({ id: 0, title: '免费店铺' })
      await this.handlerInquire()
      // this.handlerSelect()
    },
    methods: {
      // async handlerSelect() {
      //   const staff = await getAction('/api/poster/type/select')
      //   this.staffSelect = staff.data
      // },
      setViews(row) {
        console.log('设置，，，', row)
        this.$refs.taskLog.showData({
          task_type: 1,
          data_id: row.id,
          title: row.mall_name,
          virtual_num: row.virtual_view_nums,
          views_num: row.view_num,
        })
      },
      handlerExport() {
        let data = JSON.parse(JSON.stringify(this.form))
        data.trades = data.trades.join(',')
        data.pay_type = data.pay_type.join(',')
        getAction('/mall/shop/lists-export', data).then((res) => {
          this.$message({ type: 'success', message: res.msg })
        })
      },
      seegoods(row) {
        const goodspages = this.$router.resolve({
          path: '/enterprise/productMan',
          query: { mall_id: row.id },
        })
        window.open(goodspages.href, '_blank')
      },
      handlerstatus(row, type) {
        getAction('/mall/shop/get-code', { type: type, mall_id: row.id }).then(
          (res) => {
            this.qrcode = res.data
            this.qrcodeshow = true
          }
        )
      },
      handlerInquire() {
        this.form.page = 1
        this.handlerlist()
      },
      handlerlist() {
        let data = JSON.parse(JSON.stringify(this.form))
        console.log('------------11', data)
        data.trades = data.trades.join(',')
        data.pay_type = data.pay_type.join(',')
        getAction('/mall/shop/lists', data).then((res) => {
          this.tabledata = res.data
          this.total = Number(res.totalCount)
        })
      },
      handleCurrentChange(val) {
        this.form.page = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.limit = val
        this.handlerlist()
      },
    },
  }
</script>

<style lang="scss">
  .ssaassd {
    .el-cascader-panel .el-scrollbar:first-child .el-checkbox {
      display: none !important;
    }
  }
</style>
