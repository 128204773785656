var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-tabs",
        { attrs: { type: "card" }, on: { "tab-click": _vm.handleClick } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "全部" } },
            [_c("allcom", { ref: "allcom" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "已删除" } },
            [_c("delcom", { ref: "delcom" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }